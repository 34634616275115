import React, { useEffect, useState } from 'react';
import SelectOrderStatus from './TogelButton/SelectOrderStatus';
import ViewOrder from './View Model/ViewOrder';

import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import OrderServices from '../services/OrderServices';
import useAsync from '../hooks/useAsync';

Modal.setAppElement('#root');


function OrderDelivered() {
  const [orders, setOrders] = useState();

  const {data,error,isLoading,run} = useAsync(OrderServices.getOrders);
  console.log(data)


  const pendingOrder = data?.data?.filter(order => order.orderStatus === "Delivered")
  const count = pendingOrder?.length
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderData, setOrderData] = useState(data?.data || []);
  

  useEffect(() => {
    if (data?.data) {
      setOrderData(
        pendingOrder.filter(order =>
          order?.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order?.orderId?.toString().includes(searchTerm)
        )
      );
    }
  }, [pendingOrder, searchTerm]);
  const handleEditDetails = (slider) => {
    setSelectedEdit(slider);
    setIsEditModalOpen(true);
    toggleActionMenu(null)
  };

  const handleDelete = (slider) => {
    setSelectedEdit(slider);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null)
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };


  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  const handleToggleStatus = id => {
    setOrders(prevOrders =>
      prevOrders.map(order =>
        order.id === id ? { ...order, status: !order.status } : order
      )
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Delivered Orders<span className="badge bg-orange">{count}</span></h2>
          <div className="container-box-top-header-right">
          </div>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
            <p>Orders Lists</p>
          </div>
          <div className="sub-title-box-right">
            <div className="d-flex justify-content-end">
              <button className="excel-btn"><img src="img/excel.svg" />Download</button>
            </div>
          </div>
        </div>
        <div className="container-box px-0">
          <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
              <input 
                type="search" 
                name="search" 
                placeholder="Search" 
                value={searchTerm} 
                onChange={handleSearchChange} 
              />
              <button className="search-btn">Search</button>
            </div>
            <div className="container-bo  x-top-header-right order-all-right">
              <div className="d-flex justify-content-end">
                <input type="date" name="date" className="mx-3" />
                <button className="filter-btn">Filter</button>
              </div>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{width: '100%'}}>
              <thead className="thead-new">
                <tr>
                  <th>#</th>
                  <th>Order No.</th>
                  <th>Delivery date</th>
                  <th>Order By</th>
                  <th>Name</th>
                  <th>Delivery Address</th>
                  <th>Order Amount</th>
                  <th>Order Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {pendingOrder?.map((order, index) => (
                <tr key={order?.id}>
                  <td>{index + 1}</td>
                  <td>{order?.orderId}</td>
                  <td>{order?.createdAt}</td>
                  <td>{order?.customerName}</td>
                  <td>{order?.customerName}</td>
                  <td>{order?.deliveryDetails.address}</td>
                  <td>₹{order?.totalAmount}</td>
                  <td><span className="store-name d-inline-flex align-items-center text-success"><span style={{fontSize: '10px'}}><i className="fa fa-circle me-1" /></span>Delivered</span></td>
                 
  
                  <td>
                  <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(order)} >View</button></li>
                              <li><button className="reject" onClick={() => handleDelete(order)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                  </td>
                </tr>
              ))}
              
              
              </tbody>
            </table>
          </div>
        </div>
      <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewOrder order={selectedEdit} closeModal={closeEditModal} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="HomeSlider" closeModal={closeDeleteModal}  />
        </Modal>
    </div>
  );
}

export default OrderDelivered;
