import requests from "./httpService";

const HomesliderServices = {

    getHomeSlider : async () =>{
        return requests.get(`/homeslider`);
    },
    updateHomeSlider : async (id,body) =>{
        return requests.patch(`/homeslider/${id}`,body);
    },

    createHomeSlider : async (body) =>{
        return requests.post(`/homeslider`,body);
    },

    deleteHomeSlider : async (id) =>{
        return requests.delete(`/homeslider/${id}`);
    },
    updateBanner : async (id,body) =>{
        return requests.patch(`/banner/${id}`,body)
    },
    getBanner : async () => {
        return requests.get(`/banner`)
    },
    createBanner :async (body) => {
        return requests.post(`/banner`,body)
    },
    deleteBanner :async (id) =>{
        return requests.delete(`/banner/${id}`)
    }
}

export default HomesliderServices;