import React, {useState} from 'react'

import useAsync from '../hooks/useAsync'
import RequestServies from '../services/RequestServices'
import ViewEnquiry from './View Model/ViewEnquiry'
import Modal from 'react-modal';
import HelpTogal from './TogelButton/HelpTogal'
import DeleteButton from './Button/DeleteButton'
Modal.setAppElement('#root');
function Enquiry() {


  const {data , error, isLoading ,run} = useAsync(RequestServies.getAllEnquiry);
  const count = data?.data?.length
console.log(data)
    const [isEnquiryViewModalOpen, setIsEnquiryViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleViewDetails = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setIsEnquiryViewModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEnquiryViewModal = () => {
    setIsEnquiryViewModalOpen(false);
    setSelectedEnquiry(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEnquiry(null);
  };
 
    
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;

  }

  return (
    <>
    <div className="right_col" role="main">
    <div className="title-box">
      <h2>Enquiry<span className="badge bg-orange">{count}</span></h2>
    </div>
    <div className="sub-title-box">
      <div className="sub-title-box-left">
      </div>
      <div className="sub-title-box-right">
        <button className="excel-btn"><img src="img/excel.svg" />Download</button>
      </div>
    </div>
    <div className="container-box px-0 user-manager">
      <div className="container-box-top-header px-4">
        <div className="container-box-top-header-left-2">
          <input type="search" name="search" placeholder="Search" />
          <button className="search-btn">Search</button>
        </div>
      </div>
      <div className="container-box-inner">
        <table id="example" className="table table-striped" style={{width: '100%'}}>
          <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Contact No.</th>
              <th>Message</th>
              <th>Registered Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {data?.data?.map((enquiry, index) => (

              <tr key={index}>
              <th>{index+1}</th>
              <td>{enquiry?.name}</td>
              <td>{enquiry?.phone}</td>
              <td>{ enquiry?.message} </td>
              <td>{formatDateTime(enquiry?.createdAt)}</td>
              <td className="status-toggle">
                 <HelpTogal  help={enquiry} page="Enquiry" onSuccess={run}/>
              </td>
              <td>
                <div className="action_toggle_outer">
                  <button className="action_toggle"
                   onClick={() => toggleActionMenu(index)}
                   > 
                   <i className="fa fa-ellipsis-v" />
                   </button>
                   {activeIndex === index && (
                  <div className="action_toggle_content show d-block">
                    <ul className="list-unstyled">
                    <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleViewDetails(enquiry)}>View</button></li>
                              <li><button className="reject" onClick={() => handleDelete(enquiry)} >Delete</button></li>
                    </ul>
                  </div>
                  )}
                </div>
              </td>
            </tr>

          ))}
            
            
          </tbody>
        </table>
      </div>
    </div>
    <Modal
          isOpen={isEnquiryViewModalOpen}
          onRequestClose={closeEnquiryViewModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewEnquiry data={selectedEnquiry} closeModal={closeEnquiryViewModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEnquiry} page = "Enquiry" closeModal={closeDeleteModal} onSuccess={run}/>
        </Modal>
  </div>
  </>
  )
}

export default Enquiry