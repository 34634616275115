import React, { useState, useMemo } from 'react';
import useAsync from '../hooks/useAsync';
import AppointmentServices from '../services/AppointmentServices';
import ViewDoctorEarning from './View Model/ViewEarning';
import Modal from 'react-modal';
import { utils, writeFile } from 'xlsx';

Modal.setAppElement('#root');

function DoctorEarning() {
  const { data, error, isLoading, run } = useAsync(AppointmentServices.getApointment);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
    toggleActionMenu(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function formatTime(isoString) {
    const date = new Date(isoString);
    const formattedTime = date.toLocaleTimeString();
    return formattedTime;
  }

  function downloadEarnings() {
    const formattedData = data?.data
      ?.filter(earning => earning.status === 'completed')
      ?.map((earning, index) => ({
        '#': index + 1,
        Image: process.env.REACT_APP_URL + earning?.farmer?.image,
        Name: earning?.farmer?.name,
        Age: earning?.farmer?.age,
        Fee: earning?.doctor?.fee,
        Date: formatDateTime(earning?.duration?.date),
        Time: formatTime(earning?.duration?.date),
        Address: earning?.farmer?.address,
        'Dr Name': earning?.doctor?.name,
        'Complete Status': earning?.completeStatus,
      }));

    const worksheet = utils.json_to_sheet(formattedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Earnings');
    writeFile(workbook, 'Earnings.xlsx');
  }

  // Filter data based on search query
  const filteredData = useMemo(() => {
    return data?.data
      ?.filter(earning => earning.status === 'completed')
      .filter(earning => {
        const searchLower = searchQuery.toLowerCase();
        return (
          earning?.doctor?.name.toLowerCase().includes(searchLower) ||
          earning?.farmer?.name.toLowerCase().includes(searchLower) ||
          earning?.farmer?.mobileNo.toString().includes(searchLower) ||
          earning?.doctor?.fee.toString().includes(searchLower)
        );
      });
  }, [data, searchQuery]);

  return (
    <>
      <div className="right_col" role="main">
        <div className="title-box">
          <h2>Doctor Earning List <span className="badge bg-orange">{filteredData?.length || 0}</span></h2>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
            <p>Earning</p>
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn" onClick={downloadEarnings}>
              <img src="img/excel.svg" alt="" />Download
            </button>
          </div>
        </div>
        <div className="container-box px-0">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input
                type="search"
                name="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="search-btn">Search</button>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Dr Name</th>
                  <th>Age</th>
                  <th>Fee</th>
                  <th>Date</th>
                  <th>Address</th>
                  <th>Farmer Name</th>
                  <th>Farmer Mobile No.</th>
                  <th>Status</th>
                  <th>More Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((earning, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="product-img">
                        <img src={process.env.REACT_APP_URL + earning?.doctor?.image} alt="" />
                      </div>
                    </td>
                    <td>{earning?.doctor?.name}</td>
                    <td>{earning?.doctor?.age}</td>
                    <td>{earning?.doctor?.fee}</td>
                    <td className="payment">{formatDateTime(earning.duration.date)}</td>
                    <td>{earning?.doctor?.address}</td>
                    <td>{earning?.farmer?.name}</td>
                    <td>{earning?.farmer?.mobileNo}</td>
                    <td>{earning?.status}</td>
                    <td>
                      <button
                        className="view-details-btn"
                        onClick={() => handleViewDetails(earning)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewDoctorEarning user={selectedUser} closeModal={closeModal} />
        </Modal>
      </div>
    </>
  );
}

export default DoctorEarning;
