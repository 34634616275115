import React from 'react';

import './Header/cssCopy/style.css'
import './Header/cssCopy/responsive.css'
import logo from './Header/img/pashuchikitsa-logo 5.png';
import download from './Header/img/download p.svg';
import mobile from './Header/img/phone-call.png';
import play from './Header/img/download-btn.svg';
import apple from './Header/img/download-btn1.svg'
import facebook from './Header/img/facebook (1).png'
import Instagram from './Header/img/insta.png'
import twitter from './Header/img/twitter.png'
import youtube from './Header/img/youtube.png'
import message from './Header/img/message.png'
import email from './Header/img/email.png'
import address from './Header/img/gps.png'

function Home() {
  return (
    <div>
      <div className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-2">
              <div className="header-icon">
                <a href="#"><img src={logo} alt="" /></a>
              </div>
            </div>
            <div className="col-sm-10">
              <div className="header-list">
                <ul className="list-unstyled menus d-flex justify-content-end align-items-center">
                  <li><a href="#home" className="active">Home</a></li>
                  <li><a href="#about" className="active">About</a></li>
                  <li><a href="#contact">Contact</a></li>
                  <li><a href="https://play.google.com/store/apps/details?id=com.pashuchikitsa">Download Now</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="home" className="welcome-page">
        <div className="container">
          <div className="row align-items-center">
            <div className="welcome-page-text">
              <h1>Welcome to pashu chikitsa</h1>
              <h5>Welcome to Pashuchikitsa! Connect with top vets, get accurate animal diagnostics, and shop for essential veterinary products—all in one easy app. Experience seamless care for your animals today! </h5>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="about-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about-us-img">
                <a href="#"><img src={logo} alt="" /></a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-us-content">
                <h1>About-Us</h1>
                <h6>At Pashuchikitsa, we’re dedicated to revolutionizing animal health and care. Our app connects farmers with certified veterinarians, offering precise diagnostics and convenient shopping for veterinary products. Whether you're a farmer needing reliable veterinary services or a vet looking to expand your reach, Pashuchikitsa provides a seamless experience tailored to your needs. Our user-friendly platform ensures secure transactions and efficient management of consultations and products. We aim to enhance the well-being of your animals with expert care and top-quality products, all from the convenience of your smartphone. Join us in transforming animal health care today</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="download-now">
        <div className="container">
          <div className="download-now-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="download-now-info">
                  <h1>You can have your Own App</h1>
                  <h5>Download now </h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="download-now-icon text-center">
                  <a href="#"><img src={download} alt="" /></a>
                </div>
                <div className="download-now-im justify-content-center d-flex text-center">
                  <div className="text-center ">
                    <a href="https://play.google.com/store/apps/details?id=com.pashuchikitsa"><img src={play} alt="" /></a>
                  </div>
                  <div className="text-center">
                    <a href="https://www.apple.com/app-store/"><img src={apple} alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="contact" className="contact-us">
        <div className="container">
          <div className="footer-inner">
            <div className="row align-items-center">
              <div className="col-sm-4">
                <div className="contact-site" >
                  <ul>
                    <li>
                      <span>
                        <img src={mobile} alt="" />
                      </span>
                      <a href="#"><p style={{ color: 'white' }}> +91 9257019374 </p></a>
                    </li>
                    <li>
                      <span>
                        <img src={email} alt="" />
                      </span>
                      <a href="mailto:pashuchikitsaindia@gmail.com"><p style={{ color: 'white' }}>Email: pashuchikitsaindia@gmail.com </p></a>
                    </li>
                    <li>
                      <span>
                        <img src={address} alt="" />
                      </span>
                      <a href="#"><p style={{ color: 'white' }}>Address: AC-4, Gayatri Sadan, Gayatri Marg, Sawai Jaisingh Highway, Bani Park, Jaipur, Rajasthan </p></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact-us-logo text-center">
                  <a href="#"><img src={logo} alt="" /></a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact-us-list">
                  <ul className="menus d-flex justify-content-end align-items-center">
                    <li><a href="https://www.facebook.com/pashuchikitsa.ind"><img src={facebook} alt="" /></a></li>
                    <li><a href="https://www.instagram.com/pashuchikitsa.ind/"><img src={Instagram} alt="" /></a></li>
                    <li><a href="#"><img src={message} alt="" /></a></li>
                    <li><a href="https://x.com/pashuchiktsa"><img src={twitter} alt="" /></a></li>
                    <li><a href="#"><img src={youtube} alt="" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="last-menu">
            <div className="row align-items-center">
              <div className="last-menu-list text-center">
                <ul className="menus d-flex justify-content-center align-items-center">
                  <li><a href="#home">Home</a></li>
                  <li><a href="#about">About</a></li>
                  <li><a href="#contact">Contact</a></li>
                  <li><a href="/privacy">Privacy Policy</a></li>
                  <li><a href="/terms">Terms & Conditions</a></li>
                  <li><a href="/refund">Refund Policy</a></li>
                  <li><a href="https://play.google.com/store/apps/details?id=com.pashuchikitsa">Download Now</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copy-right text-center">
            <h6>© 2024 <a href="https://truevalueinfosoft.com/">Pashuchikitsa</a> . All Rights Reserved</h6>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
