import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import FaqServices from '../../services/FaqServices'

function UpdateFAQ({ selectedData, data, closeModal } ) {
    console.log(selectedData);
  const [faq, setFaq] = useState({
    title: selectedData?.title,
    description: selectedData?.description,
    userFor: selectedData?.userFor,
    category: selectedData?.category
  });
//   const [formValues, setFormValues] = useState({
//     category: '',
//     name: '',
//     description: '',
//     weight: [{ value: '', price: '', mrp: '' }], // Updated to handle multiple entries
//     gst: '',
//     images: [], // Updated to handle multiple files
//     status: 'active',
//   });

  const [category, setCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await FaqServices.getAllFaqCategory();
      setCategoryList(response.data);
    };
    fetchCategories();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFaq({
      ...faq,
      [name]: value,
    });
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(faq);
    const response = await FaqServices.updateFaq(selectedData.id,faq);
    // console.log(response);
    if (response.status === true) {
        // onSuccess();
      alert('FAQ Update successfully');
    
    }else {
        alert('Something went wrong');
      }
  };

  return (
    
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">FAQs Edit</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
        </div>
        <div className="modal-body px-5 pb-0">
         
          <div className="container-box px-5">
            <div className="container-box-inner">
              <div className="page-details">
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-field">
                    <label>Select Category</label>
                    <select
                      name="category"
                      className="form-control"
                      value={faq.category}
                      onChange={handleInputChange}
                    >
                         <option >--select category--</option>
                      {categoryList.map((cat, index) => (
                        <option key={index} value={cat._id}>{cat.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-field">
                    <label>Select Type</label>
                    <select
                      name="userFor"
                      className="form-control"
                      value={faq.userFor}
                      onChange={handleInputChange}
                    >
                      <option value="Doctor">Doctor</option>
                      <option value="Farmer">Farmer</option>
                      <option value="All">All</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Frequently Asked Questions Title</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      value={faq.title}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Answer</label>
                    <textarea
                      name="description"
                      className="form-control video-desc"
                      value={faq.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default UpdateFAQ;
