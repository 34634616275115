import requests from "./httpService";

const BenefitServices = {

    getBenefit: async () =>{
        return requests.get(`/benefit`);
    },
    updateBenefit: async (id,body) =>{
        return requests.put(`/benefit/${id}`,body);
    },

    createBenefit : async (body) =>{
        // console.log(body)
        return requests.post(`/benefit`,body);
    },
    deleteBenefit : async (id) =>{
        return requests.delete(`/benefit/${id}`);
    }
  


}
export default BenefitServices;