import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminServices from '../../services/AdminServices';

function Login({ setIsAuthenticated, setRole }) {
  const [mobileOrEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(mobileOrEmail, password)
    
    const data = await AdminServices.login({mobileOrEmail: mobileOrEmail, password: password});
    console.log(data)
   if (data.status === true) {
    localStorage.setItem('authToken', data?.token);
    localStorage.setItem('userRole', data?.data?.role);
    localStorage.setItem('name', data?.data?.name);
    localStorage.setItem('image', data?.data?.image);
    
    const permissionsData = await AdminServices.getPermissions(data?.data?.role);
    const permissions = {};
    permissionsData.data.forEach(item => {
      permissions[item.menuItem] = item.roles[data?.data?.role];
    });
    localStorage.setItem('userPermissions', JSON.stringify(permissions));

    setIsAuthenticated(true);
      navigate('/dashboard');
   }else{
    setError("*Invalid Credentials");
   }
  };

  return (
    <div className="login-section">
      <div className="login-section-inner">
        <div className="row">
          <div className="col-md-6 pe-0">
            <div className="login-section-left">
              <h1>Login</h1>
              <p>Welcome to the Admin Panel!</p>
              <form className="user-login" >
                <div className="input-field mb-3">
                  <label htmlFor="userEmail" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="userEmail"
                    placeholder="example@example.com"
                    value={mobileOrEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                </div>
                <div className="input-field mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="••••••••••"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <h6 style={{ color: 'red' }}>{error}</h6>
                <div className="input-field mb-3">
                  <input
                    onClick={handleSubmit}
                    type="submit"
                    className="form-control"
                    id="login-btn"
                    value="Login"
                  />
                </div>
              </form>
              <p>© 2024 Pashuchikitsa Made by <a href="https://truevalueinfosoft.com/">True Value Infosoft</a>.</p>
            </div>
          </div>
          <div className="col-md-6 ps-0">
            <div className="login-section-right">
              <div className="logo-field">
                <img src="img/logo.svg" alt="Logo" />
              </div>
              <div className="light-field px-4">
                <img src="img/cuate.svg" alt="Illustration" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
