import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EducationServices from '../../services/EducationServices';

function AddEducation() {
  const [formValues, setFormValues] = useState({
    title: '',
    content: '',
    image: '',
    date: '',
    user: '',
    
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        formData.append(key, formValues[key]);
      }
      const response = await EducationServices.createEducation(formData);
      console.log(response);
      alert('Education Added Successfully');
    } catch (error) {
      console.error('Failed to add Education details', error);
      alert('Failed to add Education details');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Education Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/education">Education List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="input-field">
                    <label>Title</label>
                    <input type="text" name="title" className="form-control" value={formValues.title}
                      onChange={handleInputChange} placeholder="New Education"  required />
                  </div>
                </div>               
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Description</label>
                    <textarea className="form-control video-desc" name="content" value={formValues.content}
                      onChange={handleInputChange} placeholder="New education" required />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-field">
                    <label>Date</label>
                    <input type="date" name="date" className="form-control" value={formValues.date}
                      onChange={handleInputChange} placeholder="dd/mm/yyyy" required />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Upload<span className="red">*</span></label>
                    <input type="file" name="image" className="form-control" onChange={handleFileChange} required />
                    <p><span>Drop an image or Paste URL (upto resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp</p>
                    <div className="file-preview text-center">
                      <img id="uploadFile" src={previewImage} alt="your image" />
                    </div>
                  </div>
                </div>
              </div>
              <button className="submit-green-btn">Add Education</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEducation;
