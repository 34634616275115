import requests from "./httpService";

const AnimalCategoryServices = {

    getAnimalCategory: async () =>{
        return requests.get(`/anicat`);
    },
    updateAnimalCategory : async (id,body) =>{
        return requests.put(`/anicat/${id}`,body);
    },

    createAnimalCategory : async (body) =>{
        console.log(body)
        return requests.post(`/anicat`,body);
    },
    deleteAnimalCategory : async (id) =>{
        return requests.delete(`/anicat/${id}`);
    },
     getBreedByCategory : async (categoryId) => {
        return requests.get(`/breed/category/${categoryId}`);
     }


}
export default AnimalCategoryServices;