import requests from "./httpService";

const FeedbackServices = {

    getFeedback : async () =>{
       
        return requests.get(`/feedback`);
    },
    updateApointment : async (id,body) =>{
        return requests.patch(`/feedback/${id}`,body);
    },

    deleteFeedback  : async (id) =>{
        return requests.delete(`/feedback/${id}`);
    }
 

}

export default FeedbackServices;