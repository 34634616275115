import React, { useState, useEffect } from 'react';
import HomesliderServices from '../../services/HomeSliderServices';
function SelectSliderSequence({ data,page, onSuccess }) {
    
  const [selectedSequence, setSelectedSequence] = useState(data.sequence || 1);

  useEffect(() => {
    setSelectedSequence(data.sequence || 1);
  }, [data]);

  const handleSequenceChange = async (event) => {
    const newSequence = parseInt(event.target.value, 10);
    setSelectedSequence(newSequence);
    if(page==="banner"){
      try {
        await HomesliderServices.updateBanner(data._id, {
          sequence: newSequence,
        });
        onSuccess();
      } catch (error) {
        console.error('Failed to update sequence', error);
        // Handle error accordingly, e.g., show an alert or notification
      }
    }else{
    try {
      await HomesliderServices.updateHomeSlider(data._id, {
        sequence: newSequence,
      });
      onSuccess();
    } catch (error) {
      console.error('Failed to update sequence', error);
      // Handle error accordingly, e.g., show an alert or notification
    }
  }
  };

  return (
    <select
      className="sequence-select"
      value={selectedSequence}
      onChange={handleSequenceChange}
    >
      {[1, 2, 3, 4, 5, 6].map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
}

export default SelectSliderSequence;