import React, { useState, useEffect } from 'react';
import FaqServices from '../services/FaqServices';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import FaqUpdate from './Update Model/UpdateFaq';
import DeleteButton from './Button/DeleteButton';

Modal.setAppElement('#root');

function FaqList() {
    const [categories, setCategories] = useState([]);
    const [faqsByCategory, setFaqsByCategory] = useState({});
    const [activeCategory, setActiveCategory] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedEdit, setSelectedEdit] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesResponse = await FaqServices.getAllFaqCategory();
                setCategories(categoriesResponse.data);
                if (categoriesResponse.data.length > 0) {
                    setActiveCategory(categoriesResponse.data[0]._id); // Set the first category as active initially
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchFaqsByCategory = async () => {
            if (activeCategory) {
                try {
                    const faqsResponse = await FaqServices.getAllFaqByCategory(activeCategory);
                    setFaqsByCategory(prevState => ({
                        ...prevState,
                        [activeCategory]: faqsResponse.data
                    }));
                } catch (error) {
                    console.error('Error fetching FAQs:', error);
                }
            }
        };
        fetchFaqsByCategory();
    }, [activeCategory]);

    const handleCategoryClick = (categoryId) => {
        setActiveCategory(activeCategory === categoryId ? '' : categoryId); // Toggle active category
    };

    const handleEditDetails = (faq) => {
        setSelectedEdit(faq);
        setIsEditModalOpen(true);
    };

    const handleDelete = (faq) => {
        setSelectedEdit(faq);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedEdit(null);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedEdit(null);
    };

    return (
        <div className="right_col" role="main">
            <div className="title-box">
                <h2>FAQs List <span className="badge bg-orange">{faqsByCategory[activeCategory]?.length}</span></h2>
                <div className="container-box-top-header-right">
                    <Link className="round-add-btn" to="/addFaq"><img src="img/plus.svg" alt="" /></Link>
                </div>
            </div>
            <div className="container-box px-5">
                <div className="title-box">
                    <h2>Frequently Asked Questions</h2>
                </div>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {categories.map(category => (
                        <li className="nav-item" key={category._id} onClick={() => handleCategoryClick(category._id)}>
                            <button
                                className={`nav-link ${activeCategory === category._id ? 'active' : ''}`}
                                id={`pills-${category.name}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-${category.name}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-${category.name}`}
                                aria-selected={activeCategory === category._id}
                                style={{ border: '1px solid #ccc', width: '100%', height: '40px', marginBottom: '5px' }}
                            >
                                {category.name}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    {categories.map(category => (
                        <div className={`tab-pane fade ${activeCategory === category._id ? 'show active' : ''}`} id={`pills-${category.name}`} role="tabpanel" aria-labelledby={`pills-${category.name}-tab`} key={category._id}>
                            <div className="faq-outer">
                                <div className="accordion" id={`accordion-${category._id}`}>
                                    {faqsByCategory[category._id] && faqsByCategory[category._id].map((faq, index) => (
                                        <div className="accordion-item-outer" key={index}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={`heading-${category._id}-${index}`}>
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse-${category._id}-${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapse-${category._id}-${index}`}
                                                        style={{ position: 'relative' }}
                                                    >
                                                        <span className="qustn">Q :</span> <span>{faq.title}</span>
                                                        <button
                                                            className="btn btn-default"
                                                            style={{
                                                                position: 'absolute',
                                                                backgroundColor: '#223973',
                                                                color: 'white',
                                                                right: '0%',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)'
                                                            }}
                                                        >
                                                            {faq.userFor}
                                                        </button>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse-${category._id}-${index}`}
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby={`heading-${category._id}-${index}`}
                                                    data-bs-parent={`#accordion-${category._id}`}
                                                >
                                                    <div className="accordion-body">
                                                        <div className="faq-answer-inside">
                                                            <p>Answer :</p>
                                                            <p>{faq.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="faq-edit-grp">
                                                {/* Edit and delete buttons */}
                                                <button className="video-edit-btn" style={{ backgroundColor: '#15B71B', border: 'none', padding: '5px 10px', borderRadius: '5px' }} onClick={() => handleEditDetails(faq)}>
                                                    <i className="fa fa-pencil" style={{ color: '#FFFFFF' }}></i>
                                                </button>

                                                <button className="video-delete-btn" onClick={() => handleDelete(faq)}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                contentLabel="User Details"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <FaqUpdate selectedData={selectedEdit} data={faqsByCategory[activeCategory]} closeModal={closeEditModal} />
            </Modal>
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={closeDeleteModal}
                contentLabel="Delete Confirmation"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <DeleteButton data={selectedEdit} page="faq" closeModal={closeDeleteModal} />
            </Modal>
        </div>
    );
}

export default FaqList;
