import requests from "./httpService";

const EducationServices = {

    getEducation : async () =>{
        return requests.get(`/education`);
    },
    updateEducation : async (id,body) =>{
        return requests.put(`/education/${id}`,body);
    },

    createEducation : async (body) =>{
        console.log(body)
        return requests.post(`/education`,body);
    },
    deleteEducation : async (id) =>{
        return requests.delete(`/education/${id}`);
    }
  


}

export default EducationServices;