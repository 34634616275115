import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CommunityCategoryServices from '../../services/CommunityCategoryServices.js';

function AddCommunityCategory() {
  const [formValues, setFormValues] = useState({ 
    name: '' 

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {

      const res = await CommunityCategoryServices.createCommunityCategory({"name": formValues.name});
      console.log(res);
      if (res.status === true) {
        alert('Category added successfully');
        setFormValues({ name: '' });
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add category', error);
      alert('Failed to add category');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>CommunityCategory Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/community-category">Community Category List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>Title*</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCommunityCategory;
