import requests from "./httpService";

const FaqServices = {

    AddFaq : async (body) =>{
        return requests.post(`/faq`,body);
    },

    AddFaqCategory : async (body) =>{                                                                                                       
        return requests.post(`/faqcat`,body);
    },

    getAllFaqCategory : async () =>{
        return requests.get(`/faqcat`);
    },
    
    UpdateFaqCategory : async (id,body) =>{
        return requests.put(`/faqcat/${id}`,body);
    },
    deleteFaqCategory : async (id) =>{
        return requests.delete(`/faqcat/${id}`);
    },

    getAllFaq : async () =>{
        return requests.get(`/faq`);
    },
    getAllFaqByCategory : async (categoryId) =>{
        return requests.get(`/faq/category/${categoryId}`);
    },


    updateFaq : async (id,body) =>{
        return requests.put(`/faq/${id}`,body);
    },
    deleteFaq : async (id) =>{
        return requests.delete(`/faq/${id}`);
    }
}

export default FaqServices;