import React, { useState, useEffect } from 'react';
import useAsync from '../hooks/useAsync';
import ContactServices from '../services/ContactServices';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

function AboutUs() {
  const { data, error, isLoading, run } = useAsync(ContactServices.getAboutus);
  const [formValues, setFormValues] = useState({ aboutUs: '', aboutUsHindi: '', data: "aboutUs" });

  useEffect(() => {
    if (data) {
      setFormValues({
        aboutUs: data?.data?.English || '',
        aboutUsHindi: data?.data?.Hindi || '',
        data: "aboutUs"
      });
    }
  }, [data]);

  const handleInputChange = (name, value) => {
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ContactServices.updateAppPolicy(formValues);
      alert('About Us content updated successfully!');
    } catch (error) {
      console.error('Failed to update About Us content:', error);
      alert('Failed to update About Us content. Please try again.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>About Us</h2>
      </div>
      <div className="container-box p-0 profile-container py-0">
        <div className="container-box-inner p-5">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label><b>English</b></label>
                <ReactQuill
                  theme="snow"
                  value={formValues.aboutUs}
                  onChange={(value) => handleInputChange('aboutUs', value)}
                  className="form-control"
                />
              </div>
              <div className="input-field">
                <label><b>Hindi</b></label>
                <ReactQuill
                  theme="snow"
                  value={formValues.aboutUsHindi}
                  onChange={(value) => handleInputChange('aboutUsHindi', value)}
                  className="form-control"
                />
              </div>
              <button className="submit-green-btn">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
