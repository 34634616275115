import React, { useState, useEffect } from 'react';
import OrderServices from '../../services/OrderServices';
function SelectOrderStatus({ data,page, onSuccess }) {
    
  const [selectedSequence, setSelectedSequence] = useState(data.orderStatus || "pending");

  useEffect(() => {
    setSelectedSequence(data.orderStatus || "pending");
  }, [data]);

  const handleSequenceChange = async (event) => {
    const newSequence = event.target.value;
    setSelectedSequence(newSequence);
    try {
      await OrderServices.updateOrder(data._id, {
        orderStatus: newSequence,
      });
      onSuccess();
    } catch (error) {
      console.error('Failed to update Order Status', error);
      // Handle error accordingly, e.g., show an alert or notification
    }
    
  };

  return (
    <select
      className="form-select"
      value={selectedSequence}
      onChange={handleSequenceChange}
    >
      
        <option key="Cancle" value="Cancle">
        Cancle
        </option>
        <option key="Pending" value="Pending">
        Pending
        </option>
        <option key="Confirmed" value="Confirmed">
        Confirmed
        </option>
        <option key="OutForDeliver" value="OutForDeliver">
        OutForDeliver
        </option>
        <option key="Delivered" value="Delivered">
        Delivered
        </option>
      
    </select>
  );
}

export default SelectOrderStatus;