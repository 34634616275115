import requests from "./httpService";

const OrderServices = {

    getOrders : async () =>{
        return requests.get(`/order`);
    },
    updateOrder : async (id,body) =>{
        return requests.put(`/order/${id}`,body);
    }


}

export default OrderServices;