import React, { useState, useEffect } from 'react';
import PetServices from '../../services/PetServices';
import AnimalCategoryServices from '../../services/AnimalServices';

function PetUpdate({ pet, closeModal, onSuccess, categories = [], breeds = [] }) {
  const [formValues, setFormValues] = useState({
    user: "666accae6be0c6e12dc8a712",
    petName: '',
    petBreed: '',
    title: '',
    description: '',
    category: '',
    age: {
      year: '',
      month: ''
    },
    duration: {
      startDate: '',
      endDate: ''
    },
    gender: '',
    weight: '',
    dateOfBirth: ''
  });

  const [categoryOptions, setCategoryOptions] = useState(categories);
  const [breedOptions, setBreedOptions] = useState(breeds);
  const [previewImages, setPreviewImages] = useState({
    frontImage: 'img/placeholder-img.png',
    endImage: 'img/placeholder-img.png',
    sideImage: 'img/placeholder-img.png'
  });

  useEffect(() => {
    if (pet) {
      setFormValues({
        user: pet.user || "666accae6be0c6e12dc8a712",
        petName: pet.petName || '',
        petBreed: pet.petBreed || '',
        title: pet.title || '',
        description: pet.description || '',
        category: pet.category.name || '',
        age: {
          year: pet.age?.year || '',
          month: pet.age?.month || ''
        },
        duration: {
          startDate: pet.duration?.startDate ? formatDateTime(pet.duration.startDate) : '',
          endDate: pet.duration?.endDate ? formatDateTime(pet.duration.endDate) : '',
        },
        gender: pet.gender || '',
        weight: pet.weight || '',
        dateOfBirth: pet.dateOfBirth ? formatDateTime(pet.dateOfBirth) : ''
      });

      setPreviewImages({
        frontImage: pet.frontImage ? process.env.REACT_APP_URL + pet.frontImage : 'img/placeholder-img.png',
        endImage: pet.endImage ? process.env.REACT_APP_URL + pet.endImage : 'img/placeholder-img.png',
        sideImage: pet.sideImage ? process.env.REACT_APP_URL + pet.sideImage : 'img/placeholder-img.png'
      });

      // Fetch breeds based on the existing category
      if (pet.category) {
        fetchBreeds(pet.category);
      }
    }
  }, [pet]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const res = await AnimalCategoryServices.getAnimalCategory();
      
      // Log the response for debugging
      console.log('API Response:', res);
      
      if (res.status === true && Array.isArray(res.data)) {
        setCategoryOptions(res.data);
      } else {
        // alert('Failed to fetch categories or categories data is empty');
        console.error('Error fetching categories:', res);
      }
    } catch (error) {
      console.error('Failed to fetch categories', error);
      // alert('Failed to fetch categories');
    }
  };

  const fetchBreeds = async (categoryId) => {
    try {
      const res = await AnimalCategoryServices.getBreedByCategory(categoryId);
      if (res.status === true) {
        setBreedOptions(res.data);
      } else {
        // alert('Failed to fetch breeds');
      }
    } catch (error) {
      console.error('Failed to fetch breeds', error);
      // alert('Failed to fetch breeds');
    }
  };

  const handleCategoryChange = async (e) => {
    const { value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      category: value,
      petBreed: '',
    }));

    if (value) {
      fetchBreeds(value);
    } else {
      setBreedOptions([]);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages(prev => ({
          ...prev,
          [name]: reader.result
        }));
      };
      reader.readAsDataURL(file);
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: file
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        if (formValues[key] instanceof File) {
          formData.append(key, formValues[key]);
        } else if (typeof formValues[key] === 'object') {
          for (const subKey in formValues[key]) {
            formData.append(`${key}[${subKey}]`, formValues[key][subKey]);
          }
        } else {
          formData.append(key, formValues[key]);
        }
      }

      await PetServices.updatePet(pet.id, formData);
      alert('Pet updated successfully');
      onSuccess();
      closeModal();
    } catch (error) {
      console.error('Failed to update Pet', error);
      alert('Failed to update Pet');
    }
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    return date.toISOString().split('T')[0];
  }
// console.log(">>>>>>>",formValues);
  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Pet Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Title</label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            value={formValues.title}
                            onChange={handleInputChange}
                            placeholder="Tell us about your Pet"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className="form-control video-desc"
                            value={formValues.description}
                            onChange={handleInputChange}
                            placeholder="Describe your pet"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Pet’s Name</label>
                          <input
                            type="text"
                            name="petName"
                            className="form-control"
                            value={formValues.petName}
                            onChange={handleInputChange}
                            placeholder="Pet's Name"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Pet Type<span className="red">*</span></label>
                          <select
                            name="category"
                            className="form-control"
                            value={formValues.category}
                            onChange={handleCategoryChange}
                          >
                            <option value="">Select Category</option>
                            {categoryOptions.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label htmlFor="petBreed">Pet’s Breed</label>
                          <select
                            name="petBreed"
                            id="petBreed"
                            className="form-control"
                            value={formValues.petBreed}
                            onChange={handleInputChange}
                            disabled={!formValues.category}
                          >
                            <option value="">Select Breed</option>
                            {breedOptions.map((breed) => (
                              <option key={breed._id} value={breed._id}>
                                {breed.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-field">
                          <label>Age (Years)</label>
                          <input
                            type="number"
                            name="age.year"
                            className="form-control"
                            value={formValues.age.year}
                            onChange={(e) => setFormValues(prevValues => ({
                              ...prevValues,
                              age: { ...prevValues.age, year: e.target.value }
                            }))}
                            placeholder="Years"
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-field">
                          <label>Age (Months)</label>
                          <input
                            type="number"
                            name="age.month"
                            className="form-control"
                            value={formValues.age.month}
                            onChange={(e) => setFormValues(prevValues => ({
                              ...prevValues,
                              age: { ...prevValues.age, month: e.target.value }
                            }))}
                            placeholder="Months"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            name="dateOfBirth"
                            className="form-control"
                            value={formValues.dateOfBirth}
                            onChange={handleInputChange}
                            placeholder="Date of Birth"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Weight (kg)</label>
                          <input
                            type="text"
                            name="weight"
                            className="form-control"
                            value={formValues.weight}
                            onChange={handleInputChange}
                            placeholder="Weight"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Gender</label>
                          <select
                            name="gender"
                            className="form-control"
                            value={formValues.gender}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Duration Start Date</label>
                          <input
                            type="date"
                            name="duration.startDate"
                            className="form-control"
                            value={formValues.duration.startDate}
                            onChange={(e) => setFormValues(prevValues => ({
                              ...prevValues,
                              duration: { ...prevValues.duration, startDate: e.target.value }
                            }))}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Duration End Date</label>
                          <input
                            type="date"
                            name="duration.endDate"
                            className="form-control"
                            value={formValues.duration.endDate}
                            onChange={(e) => setFormValues(prevValues => ({
                              ...prevValues,
                              duration: { ...prevValues.duration, endDate: e.target.value }
                            }))}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="input-field">
                          <label>Front Image</label>
                          <input
                            type="file"
                            name="frontImage"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                          <img src={previewImages.frontImage} alt="Front Preview" className="img-thumbnail mt-2" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="input-field">
                          <label>Side Image</label>
                          <input
                            type="file"
                            name="sideImage"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                          <img src={previewImages.sideImage} alt="Side Preview" className="img-thumbnail mt-2" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="input-field">
                          <label>End Image</label>
                          <input
                            type="file"
                            name="endImage"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                          <img src={previewImages.endImage} alt="End Preview" className="img-thumbnail mt-2" />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                      <button type="submit" className="btn btn-primary">Save changes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetUpdate;
