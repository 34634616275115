import React, { useState, useEffect } from 'react';
import ProductServices from '../../services/ProductServices';

function ProductUpdate({ selectedData, data, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    weight: [{ value: '', price: '', mrp: '' }],
    gst: '',
    images: [],
    status: 'active',
  });

  const [existingImages, setExistingImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  useEffect(() => {
    if (selectedData) {
      setFormValues({
        name: selectedData.name || '',
        description: selectedData.description || '',
        weight: selectedData.weight || [{ value: '', price: '', mrp: '' }],
        gst: selectedData.gst || '',
        images: [], // Reset new images
        status: selectedData.status || 'active',
      });
      setExistingImages(selectedData.images || []);
      setPreviewImages([]); // Clear preview images on selection
    }
  }, [selectedData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleArrayChange = (index, event) => {
    const { name, value } = event.target;
    const updatedWeight = formValues.weight.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormValues((prevValues) => ({
      ...prevValues,
      weight: updatedWeight,
    }));
  };

  const handleAddFields = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      weight: [...prevValues.weight, { value: '', price: '', mrp: '' }],
    }));
  };

  const handleRemoveFields = (index) => {
    const updatedWeight = formValues.weight.filter((_, i) => i !== index);
    setFormValues((prevValues) => ({
      ...prevValues,
      weight: updatedWeight,
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFormValues((prevValues) => ({
      ...prevValues,
      images: files,
    }));

    const previewFiles = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(previewFiles).then((images) => {
      setPreviewImages(images);
    });
  };

  const handleRemoveExistingImage = (index) => {
    const updatedImages = existingImages.filter((_, i) => i !== index);
    setExistingImages(updatedImages);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        if (key === 'images') {
          formValues[key].forEach((image) => {
            formData.append('images', image);
          });
        } else if (key === 'weight') {
          formValues[key].forEach((item, index) => {
            formData.append(`weight[${index}][value]`, item.value);
            formData.append(`weight[${index}][price]`, item.price);
            formData.append(`weight[${index}][mrp]`, item.mrp);
          });
        } else {
          formData.append(key, formValues[key]);
        }
      }

      const res = await ProductServices.updateProduct(selectedData._id, formData);
      if (res.status === true) {
        alert('Product updated successfully');
        onSuccess();
        closeModal();
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to update product', error);
      alert('Failed to update product');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Product</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Product Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={formValues.name}
                            onChange={handleInputChange}
                            placeholder="Product"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className="form-control video-desc"
                            value={formValues.description}
                            onChange={handleInputChange}
                            placeholder="Description"
                          />
                        </div>
                      </div>

                      {formValues.weight.map((item, index) => (
                        <div key={index} className="row">
                          <div className="col-sm-3">
                            <div className="input-field">
                              <label>Product Weight</label>
                              <input
                                type="text"
                                name="value"
                                className="form-control"
                                value={item.value}
                                onChange={(event) => handleArrayChange(index, event)}
                                placeholder="1Kg"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="input-field">
                              <label>Price</label>
                              <input
                                type="text"
                                name="price"
                                className="form-control"
                                value={item.price}
                                onChange={(event) => handleArrayChange(index, event)}
                                placeholder="₹500"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="input-field">
                              <label>MRP</label>
                              <input
                                type="text"
                                name="mrp"
                                className="form-control"
                                value={item.mrp}
                                onChange={(event) => handleArrayChange(index, event)}
                                placeholder="₹500"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 d-flex align-items-center">
                            <div className="input-field">
                              <button
                                type="button"
                                onClick={() => handleRemoveFields(index)}
                                className="btn btn-danger"
                              >
                                Remove
                              </button>&nbsp; &nbsp;
                              <button
                                type="button"
                                onClick={handleAddFields}
                                className="btn btn-success ml-2"
                              >
                                Add variant
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>GST</label>
                          <input
                            type="text"
                            name="gst"
                            className="form-control"
                            value={formValues.gst}
                            onChange={handleInputChange}
                            placeholder="GST percentage"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Existing Images</label>
                          <div className="image-preview">
                            {existingImages.length > 0 ? (
                              existingImages.map((image, index) => (
                                <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                  <img src={image} alt={`existing ${index}`} style={{ maxWidth: '100px', margin: '5px' }} />
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveExistingImage(index)}
                                    style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      backgroundColor: 'red',
                                      color: 'white',
                                      border: 'none',
                                      borderRadius: '50%',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              ))
                            ) : (
                              <p>No existing images</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Upload New Images</label>
                          <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleFileChange}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="image-preview">
                          {previewImages.length > 0 ? (
                            previewImages.map((image, index) => (
                              <img key={index} src={image} alt={`preview ${index}`} style={{ maxWidth: '100px', margin: '5px' }} />
                            ))
                          ) : (
                            <p>No new images selected</p>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Status</label>
                          <select
                            name="status"
                            className="form-control"
                            value={formValues.status}
                            onChange={handleInputChange}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <button type="submit" className="btn btn-primary">Update Product</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductUpdate;
