import React, { useState, useEffect } from 'react';
import useAsync from '../hooks/useAsync';
import ContactServices from '../services/ContactServices';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

function PrivacyPolicy() {
  const { data, error, isLoading } = useAsync(ContactServices.getPrivicyPolicy);
  const [formValues, setFormValues] = useState({ privacyPolicy: '', privacyPolicyHindi: '', data: "privacyPolicy" });

  useEffect(() => {
    if (data) {
      setFormValues({
        privacyPolicy: data?.data?.English || '',
        privacyPolicyHindi: data?.data?.Hindi || '',
        data: "privacyPolicy"
      });
    }
  }, [data]);

  const handleInputChange = (name, value) => {
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await ContactServices.updateAppPolicy(formValues);
      console.log(res);
      alert('Privacy Policy updated successfully!');
    } catch (error) {
      console.error('Failed to update Privacy Policy:', error);
      alert('Failed to update Privacy Policy. Please try again.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Privacy Policy</h2>
      </div>
      <div className="container-box p-0 profile-container py-0">
        <div className="container-box-inner p-5">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label><b>English</b></label>
                <ReactQuill
                  theme="snow"
                  value={formValues.privacyPolicy}
                  onChange={(value) => handleInputChange('privacyPolicy', value)}
                  className="form-control"
                />
              </div>
              <div className="input-field">
                <label><b>Hindi</b></label>
                <ReactQuill
                  theme="snow"
                  value={formValues.privacyPolicyHindi}
                  onChange={(value) => handleInputChange('privacyPolicyHindi', value)}
                  className="form-control"
                />
              </div>
              <button className="submit-green-btn">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
