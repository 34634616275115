import React, { useState } from 'react';
import Modal from 'react-modal';
import NotificationServices from '../services/NotificationServices';
import { Link } from 'react-router-dom';

const PushNotification = () => {
    const [title, setTitle] = useState('');
    const [userRole, setUserRole] = useState('');
    const [message, setMessage] = useState('');
    const [scheduledDate, setScheduledDate] = useState('');

    const handleSubmit = async(event) => {
        event.preventDefault();
        
        const notificationData = {
            title,
            userRole,
            message,
            scheduledDate,
        };
        // You can call your NotificationServices to send the data
        try {
            const res = await NotificationServices.sendPushNotification(notificationData)
            console.log('Notification sent successfully:', res);
            if(res.status === true) {   
                alert('Notification sent successfully');
            }else{
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to send notification:', error);
            alert('Something went wrong');
        }
       
           
    };

    return (
        <div className="right_col" role="main">
            <div className="title-box">
                
                <div className="container-box-top-header-right"></div>
            </div>
            <div className="sub-title-box">
        <div className="sub-title-box-left" >
        <h2>Push Notification</h2>

        </div>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/push-notification-list ">Push Notification List</Link>
        </div>
        
      </div>
           
            <div className="container-box p-0 py-0">
                <div className="container-box-inner px-5 pb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-details profile-details">
                                <h4>Push Messages</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="title">Title</label>
                                                <input
                                                    type="text"
                                                    id="title"
                                                    className="form-control"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="userRole">User For</label>
                                                <select
                                                    id="userRole"
                                                    className="form-control"
                                                    value={userRole}
                                                    onChange={(e) => setUserRole(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select User</option>
                                                    <option value="Farmer">Farmer</option>
                                                    <option value="Doctor">Doctor</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Message</label>
                                                <textarea
                                                    id="message"
                                                    className="form-control"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="scheduledDate">Schedule Date</label>
                                                <input
                                                    type="datetime-local"
                                                    id="scheduledDate"
                                                    className="form-control"
                                                    value={scheduledDate}
                                                    onChange={(e) => setScheduledDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="submit-green-btn" type="submit">
                                        SUBMIT
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default PushNotification;
