import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import FaqServices from '../../services/FaqServices'

function AddFAQ() {
  const [faq, setFaq] = useState({
    title: '',
    description: '',
    userFor: 'All',
    category: '',
  });
//   const [formValues, setFormValues] = useState({
//     category: '',
//     name: '',
//     description: '',
//     weight: [{ value: '', price: '', mrp: '' }], // Updated to handle multiple entries
//     gst: '',
//     images: [], // Updated to handle multiple files
//     status: 'active',
//   });

  const [category, setCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await FaqServices.getAllFaqCategory();
      setCategoryList(response.data);
    };
    fetchCategories();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFaq({
      ...faq,
      [name]: value,
    });
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleAddCategory = async () => {
    if (category.trim() === '') return;
    const response = await FaqServices.AddFaqCategory({ name: category });
    setCategoryList([...categoryList, response.data]);
    setCategory('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(faq);
    const response = await FaqServices.AddFaq(faq);
    // console.log(response);
    if (response.status === true) {
      alert('FAQ added successfully');
      setFaq({
        title: '',
        description: '',
        userFor: 'All',
        category: '',
      });
    }else {
        alert('Something went wrong');
      }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>FAQs Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/faq">List FAQs</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form >
              <div className="input-field">
                <label>Add category</label>
                <input
                  type="text"
                  name="category"
                  value={category}
                  onChange={handleCategoryChange}
                  className="form-control"
                  placeholder="Category Name"
                />
                <button
                  type="button"
                  onClick={handleAddCategory}
                  className="submit-green-btn add-grey-btn"
                >
                  ADD
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-field">
                    <label>Select Category</label>
                    <select
                      name="category"
                      className="form-control"
                      value={faq.category}
                      onChange={handleInputChange}
                    >
                         <option >--select category--</option>
                      {categoryList.map((cat, index) => (
                        <option key={index} value={cat._id}>{cat.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-field">
                    <label>Select Type</label>
                    <select
                      name="userFor"
                      className="form-control"
                      value={faq.userFor}
                      onChange={handleInputChange}
                    >
                      <option value="Doctor">Doctor</option>
                      <option value="Farmer">Farmer</option>
                      <option value="All">All</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Frequently Asked Questions Title</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      value={faq.title}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Answer</label>
                    <textarea
                      name="description"
                      className="form-control video-desc"
                      value={faq.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFAQ;
