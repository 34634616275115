import React, { useState } from 'react';
import useAsync from '../hooks/useAsync'; // Assuming you have a custom hook for async operations
import BenefitServices from '../services/BenefitServices'; // Assuming you have defined service methods
import Modal from 'react-modal';
import BenifitUpdate from './Update Model/BenifitUpdate';

Modal.setAppElement('#root'); // Setting the app element for modal accessibility

function Benifit() {
  const { data, error, isLoading, run } = useAsync(BenefitServices.getBenefit);
  const count = data?.data?.length;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  const handleEditDetails = (benefit) => {
    setSelectedEdit(benefit);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
    run(); // Refresh data after modal close
  };

  return (
    <>
      <div className="right_col" role="main">
        <div className="title-box">
          <h2>Benefit <span className="badge bg-orange">{count}</span></h2>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
            <p>Benefit List</p>
          </div>
        </div>
        <div className="container-box px-0 user-manager">
          <div className="container-box-inner">
            <table className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Messages</th>
                  <th>Process</th>
                  <th>Days</th>
                  <th>Fee</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((benefit, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className='address'>
                      <ul>
                        {benefit?.message.map((msg, idx) => (
                          <li key={idx}>{msg}</li>
                        ))}
                      </ul>
                    </td>
                    <td className='address'>
                      <ul>
                        {benefit?.process.map((msg, idx) => (
                          <li key={idx}>{msg}</li>
                        ))}
                      </ul>
                    </td>
                    <td>{benefit?.days}</td>
                    <td>{benefit?.fee}</td>
                    <td>
                      <div className="action_toggle_outer">
                        <button className="action_toggle" onClick={() => handleEditDetails(benefit)}>
                          <i className="fa fa-edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Benefit Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <BenifitUpdate Benifit={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  );
}

export default Benifit;
