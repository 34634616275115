import React from "react";

function ViewOrder({ order, closeModal }) {
  return (
    <div
      className="modal fade viewbox edit-box show d-block"
      id="customerModal"
      tabIndex={-1}
      aria-labelledby="customerModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="customerModal">
              Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body px-5">
            <div className="d-box mt-3 pt-1">
              <ul className="d-flex list-unstyled justify-content-between">
                <li>
                  <span>Name: </span>
                  {order?.customerName}
                </li>
                {/* {order?.orderItems?.map((item, index) => (
                     <li><span>Product Name {index + 1}: </span>{item?.productName}</li>
                  ))} */}

                <li>
                  <span>Price: </span> ₹{order?.totalAmount}
                </li>
                <li>
                  <span>Order Id: </span> {order?.orderId}
                </li>
                <li>
                  <span>Date: </span>
                  {order?.createdAt}
                </li>
              </ul>
            </div>
            <div className="address mt-4">
              <span>
                <a href="#">
                  <img src="img/google-map-icon.svg" />
                </a>
              </span>
              <span>  {`${order?.deliveryDetails.address}, ${order?.deliveryDetails.city}, ${order?.deliveryDetails.state}, ${order?.deliveryDetails.postalCode}`}</span>
            </div>
            <table class="table mt-5">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Weight</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems?.map((item, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{item?.productName}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.weight}</td>
                    <td>{item?.price}</td>
                  </tr>
                ))}
                <tr>
                    <th scope="row"> </th>
                    <td> </td>
                    <td> </td>
                    <td><b>Total Amount</b></td>
                    <td>{order?.totalAmount}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOrder;
