import React from 'react'

function ViewDoctorEarning({ user  , closeModal}) {
  if (!user) return null;
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const url = 'uploads/'
  const { date, time } = formatDateTime(user.createdAt);
  return (
    <div className="modal fade viewbox edit-box show d-block"  id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="manufacturerModal">Details</h5>
              <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <div className="modal-body px-5">
              <div className="d-box mt-3 pt-1">
                <ul className="d-flex list-unstyled justify-content-between">
                <li><span>Name: </span>{user?.doctor?.name}</li>
                <li><span>Mobile No: </span> {user?.doctor?.mobileNo}</li>
                <li><span>State: </span> {user?.doctor?.state}</li>
                <li><span>District: </span> {user?.doctor?.district}</li>
                <li><span>City: </span> {user?.doctor?.city}</li>
                <li><span>Village: </span> {user?.doctor?.village}</li>
                <li><span>Block: </span> {user?.doctor?.block}</li>
                <li><span>Ward: </span> {user?.doctor?.ward}</li>
                <li><span>Age: </span> {user?.doctor?.age}</li>
                <li><span>Other: </span> {user?.doctor?.other}</li>
                <li><span>Fee :</span>{user?.doctor?.fee}</li>
                <li><span>Emergency Fee :</span>{user?.doctor?.emergencyFee}</li>
                <li><span>Farmer Name :</span>{user?.farmer?.name}</li>
                <li><span>Experience :</span>{user?.doctor?.experience}</li>
                <li><span>Registration date: </span>{date}</li>
                <div style={{ width: '100%' }}>
                  <li><span>Address: </span>{user?.doctor?.address}</li>
                </div>
              
                </ul>
               
            
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ViewDoctorEarning