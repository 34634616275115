import requests from "./httpService";

const UserServices = {

    getPrivicyPolicy : async () =>{
        return requests.get(`/app/appPolicy?data=privacyPolicy`);
    },
    updateAppPolicy : async (body) =>{
        return requests.put(`/app/appPolicy`,body);
    },

    getTermCondition : async () =>{
        return requests.get(`/app/appPolicy?data=termsAndCondition`);
    },

    getAboutus : async () =>{
        return requests.get(`/app/appPolicy?data=aboutUs`);
    },
    howToImage: async () =>{
        return requests.get(`/app/howtoallimage`);
    },
    updateHowToImage : async (body) =>{
        console.log(body);
        return requests.patch(`/app/howtoimage`,body);
    }

}

export default UserServices;