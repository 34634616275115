import React, { useState, useEffect } from 'react';
import HelpTogal from '../TogelButton/TogalButtonForStatus';
import EducationServices from '../../services/EducationServices';

function EducationUpdate({ education, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    title: '',
    content: '',
    image: '',
    date: '',
    status: '',
  });
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); 

  useEffect(() => {
    if (education) {
      setFormValues({
        title: education?.title || '',
        content: education?.content || '',
        image: education?.image || '',
        date: education?.date ? formatDateTime(education.date) : '',
        status: education?.status || 'Inactive',
      });
      if (education.image) {
        setPreviewImage(process.env.REACT_APP_URL + education.image);
      }
    }
  }, [education]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    return formattedDate;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        if (key !== 'image' || formValues[key] instanceof File) {
          formData.append(key, formValues[key]);
        }
      }

      await EducationServices.updateEducation(education._id, formData);
      alert('Education updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Failed to update Education', error);
      alert('Failed to update Education');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Education Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-9 col-md-6">
                        <div className="input-field">
                          <label>Title</label>
                          <input type="text" name="title" className="form-control" value={formValues.title} onChange={handleInputChange} placeholder="New Education" />
                        </div>
                      </div>
                     
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Description</label>
                          <textarea rows="3" style={{ height: 'auto' }} type="text" name="content" value={formValues.content} onChange={handleInputChange} className="form-control" placeholder="New Education" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Date</label>
                          <input type="date" name="date" className="form-control" value={formValues.date} onChange={handleInputChange} placeholder="dd/mm/yyyy" />
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="input-field">
                          <label>Upload<span className="red">*</span></label>
                          <input type="file" name="image" className="form-control" onChange={handleFileChange} />
                          <p><span>Drop an image or Paste URL (up to resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp</p>
                          <div className="file-preview text-center">
                            <img id="uploadFile" src={previewImage} alt="your image" />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-3 ps-5">
                        <div className="input-field">
                          <label>Status</label>
                          <div className="barcode-range-inner-field">
                            <p>Active/Inactive</p>
                            <div className="status-toggle">
                              <HelpTogal help={education} page="education" onSuccess={onSuccess} />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <button className="submit-green-btn">Update Education</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationUpdate;
