import React from 'react'

function ViewUser({ user, closeModal }) {
  if (!user) return null;
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const url = 'uploads/'
  const { date, time } = formatDateTime(user.createdAt);
  return (
    <div className="modal fade viewbox edit-box show d-block" id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="manufacturerModal">Details</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body px-5">
            <div className="d-box mt-3 pt-1">
              <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                <li><span>Name: </span>{user.name}</li>
                <li><span>Mobile No: </span> {user.mobileNo}</li>
                <li><span>State: </span> {user.state}</li>
                <li><span>District: </span> {user?.district}</li>
                <li><span>City: </span> {user?.city}</li>
                <li><span>Village: </span> {user?.village}</li>
                <li><span>Block: </span> {user?.block}</li>
                <li><span>Ward: </span> {user?.ward}</li>
                <li><span>Age: </span> {user?.age}</li>
                <li><span>Other: </span> {user?.other}</li>
                <li><span>Registration date: </span>{date}</li>
                <div style={{ width: '100%' }}>
                  <li><span>Address: </span>{user?.address}</li>
                </div>
              </ul>


              {/* {user.userType !== 'customer' && (
                  <>
                  <li><span>Details about {user.userType}: </span>{user?.profile?.detailAbout}</li>
                  <li><span>GST No* :   </span>{user?.profile?.gstNo}</li> 
                  <li><span>CIN No. : </span>{user?.profile?.cin}</li>
                  </>)} */}
              {/* {user.userType == 'serviceCenter' && (
                  <>
                  <li><span>Company Authorized : </span>{user?.profile?.authorization}</li>
                  </>
                )} */}
              {/* <li><span>Payment :</span>{user?.plan?.planName}</li> */}
              {/* <div className="address mt-4">
                  <span><a href="#"><img src="img/google-map-icon.svg" /></a></span>
                  <span>{user?.address}</span>
                </div> */}
              {/* {user.userType !== 'customer' && (
                  <>
                  
                <div className="mt-5 d-box-grp">
                  <a href={process.env.REACT_APP_URL + url +user?.profile?.gstCertificate} target='_blank'> <button className="document-update-btn"><img src="img/pdf.svg" /> GST Certificate Doc*</button> </a>
                  <a href={process.env.REACT_APP_URL + url +user?.profile?.proofOfIdentity} target='_blank'> <button className="document-update-btn"><img src="img/pdf.svg" /> Trademark Document</button></a>
                  <a href={process.env.REACT_APP_URL + url +user?.image} target='_blank'> <button className="document-update-btn"><img src="img/pdf.svg" /> Photo*</button></a>
                  <a href={process.env.REACT_APP_URL + url +user?.profile?.cinCertificate} target='_blank'> <button className="document-update-btn"><img src="img/pdf.svg" /> CIN Certificate*</button></a>
                </div>
                </>)} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewUser