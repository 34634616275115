import React, { useEffect, useState } from 'react';
import useAsync from '../hooks/useAsync';
import ProductServices from '../services/ProductServices';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import ProductUpdate from './Update Model/ProductUpdate.js';
import TogalButtonForStatus from './TogelButton/TogalButtonForStatus.js';
import { utils, writeFile } from 'xlsx';

Modal.setAppElement('#root');

function ProductList() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { data, error, isLoading, run } = useAsync(ProductServices.getAllProduct);
  console.log(">>>>>>", data);

  useEffect(() => {
    if (data) {
      setProducts(data?.data || []); // Assuming data structure is { data: [] }
      setFilteredProducts(data?.data || []); // Set initial filtered products
    }
  }, [data]);

  useEffect(() => {
    if (products) {
      handleSearch();
    }
  }, [searchQuery, products]);

  const handleEditDetails = (product) => {
    setSelectedEdit(product);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (product) => {
    setSelectedEdit(product);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(query) ||
      product.description?.toLowerCase().includes(query) ||
      (product?.weight[0]?.value?.toString() || '').includes(query) ||
    (product.weight[0]?.price?.toString() || '').includes(query) 
    );
    setFilteredProducts(filtered);
  };

  function downloadProducts() {
    const formattedData = filteredProducts.map((product, index) => ({
      '#': index + 1,
      Name: product.name,
      Weight: product.weight[0].value,
      Price: product.weight[0].price,
      Mrp: product.weight[0].mrp,
    }));
    const worksheet = utils.json_to_sheet(formattedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Appointments');
    writeFile(workbook, 'Appointments.xlsx');
  }
  function truncateText(text, limit) {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Product List <span className="badge bg-orange">{filteredProducts.length}</span></h2>
        <div className="sub-title-box-right">
          <div className="container-box-top-header-right mb-2">
            <Link className="round-add-btn" to="/addProduct"><img src="img/plus.svg" alt="" /></Link>
          </div>
          <button className="excel-btn" onClick={downloadProducts}><img src="img/excel.svg" alt="Download" />Download</button>
        </div>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="search-btn" onClick={handleSearch}>Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Category Name</th>
                <th className="w-10">Product Name</th>
                <th>Weight</th>
                <th>Price</th>
                <th>MRP</th>
                <th>Benefit</th>
                <th>Status</th>
                <th>Multiple Varient</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={product._id}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="product-img">
                      <img src={product.images[0] ? `${process.env.REACT_APP_URL}${product?.images[0]}` : 'img/productDemo.png'} alt={product.name} />
                    </div>
                  </td>
                  <td>{product?.category?.name}</td>
                  <td>{product?.name}</td>
                  <td>{product?.weight?.length > 0 ? product.weight[0].value : 'N/A'}</td>
                  <td>{product?.weight?.length > 0 ? `₹ ${product.weight[0].price}` : 'N/A'}</td>
                  <td>{product?.weight?.length > 0 ? `₹ ${product.weight[0].mrp}` : 'N/A'}</td>
                  <td>{truncateText(product?.description, 20 || 'N/A')}</td>
                  <td className="status-toggle">
                    <div className="check-box">
                      <TogalButtonForStatus data={product} page="Product" onSuccess={run} />
                    </div>
                  </td>
                  <td>{product.weight.length > 1 ? 'Yes' : 'No'}</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button className="action_toggle" onClick={() => toggleActionMenu(index)}>
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                            <li><button className="edit" onClick={() => handleEditDetails(product)}>Edit</button></li>
                            <li><button className="reject" onClick={() => handleDelete(product)}>Delete</button></li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ProductUpdate selectedData={selectedEdit} data={data.data} closeModal={closeEditModal} onSuccess={run} />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <DeleteButton data={selectedEdit} page="product" closeModal={closeDeleteModal} onSuccess={run} />
      </Modal>
    </div>
  );
}

export default ProductList;
