import React, { useEffect, useState } from 'react';
import PetServices from '../services/PetServices';
import useAsync from '../hooks/useAsync';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import PetUpdate from './Update Model/PetUpdate';
import { Link } from 'react-router-dom';
import TogalButtonForStatus from './TogelButton/TogalButtonForStatus';
Modal.setAppElement('#root');
function ViewAllPetList() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [pets, setPets] = useState([]);
  const { data, error, isLoading, run } = useAsync(PetServices.getPetList);

  console.log(data)
  const handleEditDetails = (pet) => {
    setSelectedEdit(pet);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };
  const handleDelete = (pet) => {
    setSelectedEdit(pet);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  useEffect(() => {
    if (data) {
      setPets(data);
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  function formatDateTime(isoString) {
    if (!isoString) { return 'N/A'; }
    const date = new Date(isoString);
    // Check if the date is valid
    if (isNaN(date.getTime())) { return 'N/A'; }
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    return formattedDate;
  }



  // console.log(pets)
  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Pet List <span className="badge bg-orange">{pets.length}</span></h2>
        <div className="container-box-top-header-right">
          <Link className="round-add-btn" to="/addPet"><img src="img/plus.svg" alt="Add" /></Link>
        </div>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search" />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th className="w-10">Pet Name</th>
                <th>Pet Type</th>
                <th>Pet Breed</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Weight</th>
                <th>DOB</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pets?.data?.map((pet, index) => (
                <tr key={pet._id}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="product-img">
                      <img src={pet.frontImage ? `${process.env.REACT_APP_URL}${pet.frontImage}` : 'img/dog.png'} alt="Pet" />
                    </div>
                  </td>
                  <td>{pet?.petName}</td>
                  <td className="payment">{pet?.category?.name}</td>
                  <td>{pet?.petBreed?.name}</td>
                  <td>{`${pet?.age?.year} Years ${pet?.age?.month} Months`}</td>
                  <td>{pet?.gender?.charAt(0).toUpperCase() + pet?.gender?.slice(1)}</td>
                  <td>{pet?.weight}</td>
                  <td>{formatDateTime(pet?.dateOfBirth)}</td>
                  <td>
                    {pet?.duration?.startDate && pet?.duration?.endDate
                      ? `${new Date(pet.duration.startDate).toLocaleDateString()} - ${new Date(pet.duration.endDate).toLocaleDateString()}`
                      : 'N/A'}
                  </td>

                  <td className="status-toggle">
                    <div className="check-box">
                      <TogalButtonForStatus data={pet} page="Pet" onSuccess={run} />
                    </div>
                  </td>
                  <td>
                    <div className="action_toggle_outer">
                      <button
                        className="action_toggle"
                        onClick={() => toggleActionMenu(index)}
                      >
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                            <li><button className="edit" onClick={() => handleEditDetails(pet)} >Edit</button></li>
                            <li><button className="reject" onClick={() => handleDelete(pet)} >Delete</button></li>
                          </ul>
                        </div>
                      )}
                    </div>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <PetUpdate pet={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <DeleteButton data={selectedEdit} page="pet" closeModal={closeDeleteModal} onSuccess={run} />
      </Modal>
    </div>
  );
}

export default ViewAllPetList;

