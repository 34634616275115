import React, { useState, useEffect } from 'react';
import VerificationServices from '../../services/VerificationServices';

function SelectStatus({ data, page, onSuccess }) {
  const [selectedSequence, setSelectedSequence] = useState(data.status || "pending");

  useEffect(() => {
    setSelectedSequence(data.status || "pending");
  }, [data]);

  const handleSequenceChange = async (event) => {
    const newSequence = event.target.value;
    setSelectedSequence(newSequence);
    try {
      await VerificationServices.updateVerification(data._id, {
        status: newSequence,
      });
      onSuccess();
    } catch (error) {
      console.error('Failed to update verification status', error);
      // Handle error accordingly, e.g., show an alert or notification
    }
  };

  return (
    <select
      className="form-select"
      value={selectedSequence}
      onChange={handleSequenceChange}
    >
        <option value="booking">Booking</option>
      <option value="accept">Accept</option>
      <option value="pending">Pending</option>
      <option value="approved">Approved</option>
      <option value="rejected">Rejected</option>
      <option value="completed">Completed</option>
    </select>
  );
}

export default SelectStatus;
