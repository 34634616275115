import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BreedServices from '../../services/BreedServices.js';
import AnimalCategoryServices from '../../services/AnimalServices.js';

function AddBreed() {
  const [formValues, setFormValues] = useState({ 
    name: '', 
    category: '' 
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await AnimalCategoryServices.getAnimalCategory();
        if (res.status === true) {
          setCategories(res.data);
        } else {
          alert('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Failed to fetch categories', error);
        alert('Failed to fetch categories');
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await BreedServices.createBreed({ name: formValues.name, category: formValues.category });
      console.log(res);
      if (res.status === true) {
        alert('Breed added successfully');
        setFormValues({ name: '', category: '' });
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add breed', error);
      alert('Failed to add breed');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Breed Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/breed">Breed List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
            <div className="input-field">
                <label>Category*</label>
                <select
                  name="category"
                  className="form-control"
                  value={formValues.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Title*</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </div>
              
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBreed;
