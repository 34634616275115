import React, { useEffect, useState } from 'react';
import useAsync from '../hooks/useAsync';
import UserServices from '../services/UserServices';
import ViewUser from './View Model/viewDoctor';
import Modal from 'react-modal';
import UserStatus from './TogelButton/UserStatus';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function UserDoctor() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllDoctor);
  console.log(data);
  const count = data?.users?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const usersPerPage = 20;
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const filteredUsers = data?.data?.filter(user =>
    (user.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.email?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.mobileNo?.toString() || '').includes(searchQuery) ||
    (user.age?.toString() || '').includes(searchQuery) ||
    (user.address?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.state?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.district?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );
  const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

  const generateExcel = () => {
    const filteredData = data?.data?.map(user => ({
      userType: user.userType,
      name: user.name,
      email: user.email,
      mobileNo: user.mobileNo,
      qualification: user.qualification,
      degree: user.degree,
      adharcard: user.adharcard,
      feeType: user.feeType,
      age: user.age,
      address: user.address,
      wardNo: user.ward,
      block: user.block,
      village: user.village,
      city: user.city,
      district: user.district,
      state: user.state,
      rating: user.rating,
      totalEarning: user.totalEarning,
      status: user.status
    }));
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    XLSX.writeFile(wb, 'user_data.xlsx');
  };

  useEffect(() => {
    if (data?.users) {
      currentUsers(data.users);
    }
  }, [data]);

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>User Doctor <span className="badge bg-warning">{count}</span></h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          <p>User List</p>
        </div>
        <div className="sub-title-box-right">
          <button className="excel-btn" onClick={generateExcel}><img src="img/excel.svg" />Download</button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Type</th>
                <th>Name</th>
                <th>Image</th>
                <th>Mobile No.</th>
                <th>Email</th>
                <th>State</th>
                <th>Address</th>
                <th>Age</th>
                <th>Registration Date</th>
                <th>Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={user?._id}>
                  <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td><span className="usertype">{user?.userType}</span></td>
                  <td>{user?.name}</td>
                  <td>
                    <img src={user?.image ? `${process.env.REACT_APP_URL}${user.image}` : 'img/profile-img.png'} alt="" style={{ height: '50px', width: '50px', objectFit: 'contain' }}  />
                  </td>
                  <td>{user?.mobileNo}</td>
                  <td>{user?.email ? user.email : 'N/A'}</td>
                  <td>{user?.state}</td>
                  <td className="address">{user?.address ? user.address : 'N/A'}</td>
                  <td>{user?.age ? user.age : 'N/A'}</td>
                  <td>{user?.createdAt ? formatDate(user.createdAt) : '-'}</td>
                  <td className="status-toggle"><UserStatus user={user} onSuccess={run} /></td>
                  <td>
                    <button
                      className="view-details-btn"
                      onClick={() => handleViewDetails(user)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil(filteredUsers?.length / usersPerPage)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage - 1}
            disableInitialCallback={true}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            disabledClassName={'disabled'}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser user={selectedUser} closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default UserDoctor;
