import React, { useState, useEffect } from 'react';
import useAsync from '../hooks/useAsync';
import PetServices from '../services/PetServices';
import DeleteButton from './Button/DeleteButton';
import SellAnimalUpdate from './Update Model/SellAnimalUpdate.js';
import Modal from 'react-modal';
import TogalButtonForStatus from './TogelButton/TogalButtonForStatus.js';
Modal.setAppElement('#root');
function SellAnimalList() {
    const [activeIndex, setActiveIndex] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedEdit, setSelectedEdit] = useState(null);
  const [animal, setAnimal] = useState([]);
  const { data, error, isLoading, run } = useAsync(PetServices.getSellList)
  console.log(">>>>>>>>>",data);
  const handleEditDetails = (pet) => {
    setSelectedEdit(pet);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };
  const handleDelete = (pet) => {
    setSelectedEdit(pet);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  useEffect(() => {
    if (data) {
      setAnimal(data);
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  function formatDateTime(isoString) {
    if (!isoString) {return 'N/A';}
   const date = new Date(isoString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {return 'N/A';}
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    return formattedDate;
  }
 

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Sell Animal <span className="badge bg-orange">{animal?.data?.length}</span></h2>
        <div className="container-box-top-header-right">
          <a className="round-add-btn" href="/addSellAnimal">
            <img src="img/plus.svg" alt="Add Animal" />
          </a>
        </div>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search" />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Category Name</th>
                <th>Image</th>
                <th className=" w-10">Pet Name</th>
               
                <th>Pet Breed</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Weight</th>
                <th>DOB</th>
                {/* <th>DATE</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {animal?.data?.map((animal, index) => (
                <tr key={animal.id}>
                  <td>{index + 1}</td>
                  <td>{animal?.category?.name}</td>
                  <td>
                    <div className="product-img">
                      <img src={animal.frontImage ? `${process.env.REACT_APP_URL}${animal.frontImage}` : 'img/dog.png'} alt={animal.name} />
                    </div>
                  </td>
                  <td>{animal?.petName}</td>
          
                  <td>{animal?.petBreed?.name}</td>
                  <td>{`${animal?.age?.year} Years ${animal?.age?.month} Months`}</td>
                  <td>{animal?.gender}</td>
                  <td>{animal?.weight}</td>
                  <td>{formatDateTime(animal?.dateOfBirth)}</td>
                  {/* <td>{`${new Date(animal?.duration?.startDate).toLocaleDateString()} - ${new Date(animal?.duration?.endDate).toLocaleDateString()}`}</td> */}
                  <td className="status-toggle">
                    <div className="check-box">
                    <TogalButtonForStatus  data={animal} page="Animal" onSuccess={run}/>
                    </div>
                  </td>
                  <td>
                  <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(animal)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(animal)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <SellAnimalUpdate selectedAnimal={selectedEdit} closeModal={closeEditModal} onSuccess={run}/>
        </Modal>
      <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="sellAnimal" closeModal={closeDeleteModal}  onSuccess={run}/>
        </Modal>
    </div>
  );
}

export default SellAnimalList;
