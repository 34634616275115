import React, { useState, useEffect } from 'react';
import SelectStatus from './TogelButton/SelectVarificationStatus';
import ViewVerification from './View Model/ViewVerification';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import VerificationServices from '../services/VerificationServices';
import useAsync from '../hooks/useAsync';
import { utils, writeFile } from 'xlsx';

Modal.setAppElement('#root');

function Verification() {
  const { data, error, isLoading, run } = useAsync(VerificationServices.getVerification);
  // console.log(">>>>",data)
  const [verificationData, setVerificationData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  useEffect(() => {
    if (data?.data) {
      setVerificationData(data.data);
    }
  }, [data]);

  const handleEditDetails = (verification) => {
    setSelectedEdit(verification);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (verification) => {
    setSelectedEdit(verification);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleToggleStatus = async (id) => {
    try {
      const updatedStatus = !verificationData.find(v => v.id === id).status;
      await VerificationServices.updateVerification(id, { status: updatedStatus });
      run();
    } catch (error) {
      console.error('Failed to update verification status', error);
    }
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  }

  function formatTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleTimeString();
  }

  const filteredVerificationData = verificationData.filter((verification) => {
    const userName = verification.user && verification.user.name ? verification.user.name.toLowerCase() : '';
    const petType = verification.sellAnimal && verification.sellAnimal.petType ? verification.sellAnimal.petType.toLowerCase() : '';
    const address = verification.address ? verification.address.toLowerCase() : '';
    const status = verification.status ? verification.status.toLowerCase() : '';
  
    return userName.includes(searchTerm.toLowerCase()) ||
           petType.includes(searchTerm.toLowerCase()) ||
           address.includes(searchTerm.toLowerCase()) ||
           status.includes(searchTerm.toLowerCase());
  });
  
  function downloadVerification() {
    const formattedData = data?.data?.map((verification, index) => ({
      '#': index + 1,
      Name: verification.user?.name || 'N/A',
      PetType: verification.sellAnimal?.petType || 'N/A',
      Fee: verification.fee || 'N/A',
      Date: formatDateTime(verification.createdAt),
      Time: formatTime(verification.createdAt),
      Address: verification.address || 'N/A',
      Status: verification.status || 'N/A'
    }));
  
    const worksheet = utils.json_to_sheet(formattedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Verification Data');
    writeFile(workbook, 'verification_data.xlsx');
  }
  

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>
          Verification<span className="badge bg-orange">{verificationData.length}</span>
        </h2>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button className="search-btn">Search</button>
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn" onClick={downloadVerification}>
              <img src="img/excel.svg" alt="Download" />Download
            </button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Fee</th>
                <th>Pet Type</th>
                <th>Address</th>
                <th>Date</th>
                <th>Time</th>
                <th>Doctor Assign</th>
                <th>Doctor Mobile No.</th>
                <th>Verification Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredVerificationData.map((verification, index) => (
                <tr key={verification.id}>
                  <td>{index + 1}</td>
                  <td>{verification?.user?.name}</td>
                  <td>₹{verification?.fee}</td>
                  <td>{verification?.sellAnimal?.category?.name}</td>
                  <td>{verification?.address}</td>
                  <td>{formatDateTime(verification?.createdAt)}</td>
                  <td>{formatTime(verification?.createdAt)}</td>
                  <td>{verification?.doctor?.name || 'N/A'}</td>

                  <td>{verification?.doctor?.mobileNo || 'N/A'}</td>
                  <td>
                    <SelectStatus data={verification} page="Verification" onSuccess={run} />
                  </td>
                  <td>
                    <div className="action_toggle_outer">
                      <button className="action_toggle" onClick={() => toggleActionMenu(index)}>
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li>
                              <button className="close_toggle" onClick={() => toggleActionMenu(null)}>
                                <i className="fa fa-close" />
                              </button>
                            </li>
                            <li>
                              <button className="edit" onClick={() => handleEditDetails(verification)}>
                                View
                              </button>
                            </li>
                            <li>
                              <button className="reject" onClick={() => handleDelete(verification)}>
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewVerification verification={selectedEdit} closeModal={closeEditModal} />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <DeleteButton data={selectedEdit} page="verification" closeModal={closeDeleteModal} onSuccess={run} />
      </Modal>
    </div>
  );
}

export default Verification;
