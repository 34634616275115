import React, { useEffect, useState } from 'react'
import useAsync from '../hooks/useAsync'
import UserServices from '../services/UserServices'
import ViewUser from './View Model/ViewUser';
import Modal from 'react-modal';
import UserStatus from './TogelButton/UserStatus';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
Modal.setAppElement('#root');

function UserManager() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllUser);
  console.log(data);

  const count = data?.users?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const usersPerPage = 20;
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const filteredUsers = data?.data?.filter(user => {
    return (
      user?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.user?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.mobileNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.permanentAddress?.policeStation.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.permanentAddress?.state?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.permanentAddress?.district?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

  const generateExcel = () => {
    const filteredData = filteredUsers?.map(user => ({
      userType: user?.user?.name,           
      userMobileNo: user?.user?.mobileNo,   
      name: user?.name,                     
      image: user?.image,                   
      mobileNo: user?.mobileNo,             
      state: user?.permanentAddress?.state?.name, 
      district: user?.permanentAddress?.district?.name, 
      policeStation: user?.permanentAddress?.policeStation, 
      age: user?.age,                       
      status: user?.status                  
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    XLSX.writeFile(wb, 'user_data.xlsx');
  };

  useEffect(() => {
    if (data?.users) {
      run(UserServices.getAllUser);
    }
  }, [data]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>User  <span className="badge bg-warning">{count}</span></h2>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          <p>User List</p>
        </div>
        <div className="sub-title-box-right">
          <button className="excel-btn" onClick={generateExcel}><img src="img/excel.svg" />Download</button>
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Type Name</th>
                <th>User Mobile No.</th>
                <th>Name</th>
                <th>Image</th>
                <th>Mobile No.</th>
                <th>State</th>
                <th>District</th>
                <th>Police Station</th>
                <th>Age</th>
                <th>Verification ID</th>
                <th>Status Label</th>
                <th>Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={user?._id}>
                  <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td><span className="usertype">{user?.user?.name}</span></td>
                  <td>{user?.user?.mobileNo}</td>
                  <td>{user?.name}</td>
                  <td>
                    <img src={user?.image ? `${process.env.REACT_APP_URL}${user.image}` : 'img/profile-img.png'} alt="" style={{ height: '50px', width: '50px', objectFit: 'contain' }} />
                  </td>
                  <td>{user?.mobileNo}</td>
                  <td>{user?.permanentAddress?.state?.name}</td>
                  <td>{user?.permanentAddress?.district?.name}</td>
                  <td className="address">{user?.permanentAddress?.policeStation}</td>
                  <td>{user?.age ? user.age : 'N/A'}</td>
                  <td>{user?.verificationId ? user.verificationId : 'N/A'}</td>
                  <td style={{
                    backgroundColor: user?.status === 'Verify' ? 'green' : 'red',
                    color: 'white',
                    padding: '2px 4px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    textAlign: 'center'
                  }}>
                    {user?.status}
                  </td>
                  <td className="status-toggle"><UserStatus user={user} page={"user"} onSuccess={run} /></td>
                  <td>
                    <button className="view-details-btn" onClick={() => handleViewDetails(user)}>View</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil((filteredUsers?.length || 0) / usersPerPage)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage - 1}
            disableInitialCallback={true}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            disabledClassName={'disabled'}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser user={selectedUser} closeModal={closeModal} />
      </Modal>
    </div>
  )
}

export default UserManager;
