import requests from "./httpService";

const UserServices = {

    getAllUser : async () =>{
   
        return requests.get(`/user`);
    },
    getAllDoctor : async () =>{
       
             return requests.get(`/user?userType=Doctor`);
         },
    updateFarmer : async (id,body) =>{
        return requests.put(`/user/farmer/${id}`,body);
    },
    updateDoctor : async (id,body) =>{
        return requests.put(`/user/doctor/${id}`,body);
    },
    getAllVehicle : async () =>{
        return requests.get(`/vehical`);
    },

    getContactDetail : async () =>{
        return requests.get(`/app/contact`);
    },
    updateContactDetail : async (id,body) =>{
        console.log(">>>>>",body)
        return requests.patch(`/app/contact/${id}`,body);
    },
    
    assignVerificationToDoctor: async (verificationId, doctorId) => {
        return requests.post('/verification/assign', { verificationId, doctorId });
      },


}

export default UserServices;