import React, { useState, useEffect } from 'react';
import AppointmentServices from '../../services/AppointmentServices';// import your appointment service

function AppointmentUpdate({ selectedData, closeModal, onSuccess }) {
  console.log(selectedData)
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    age: '',
    fees: '',
    appointmentDate: '',
    time: '',
    appointmentStatus: '',
    paymentMethod: '',
    doctorName: '',
    status: false
  });

  useEffect(() => {
    if (selectedData) {
      setFormData({
        name: selectedData.farmer.name || '',
        address: selectedData.farmer.address || '',
        age: selectedData.farmer.age || '',
        fees: selectedData.doctor.fee || '',
        appointmentDate: selectedData.duration.date || '',
        time: selectedData.time.startTime || '',
        appointmentStatus: selectedData.status || '',
        paymentMethod: selectedData.paymentType || '',
        doctorName: selectedData.doctor.name || '',
        status: selectedData.completeStatus || false
      });
    }
  }, [selectedData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        duration :{date : formData.appointmentDate},
        time: formData.time,
        status: formData.status
      };

      console.log(updatedData);
      const response = await AppointmentServices.updateApointment(selectedData._id, updatedData);
      if (response.status === true) {
        alert('Appointment updated successfully');
        onSuccess();
        closeModal();
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to update appointment', error);
      alert('Failed to update appointment');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Appointment Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Name</label>
                          <input type="text" name="name" className="form-control" value={formData.name} readOnly />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-field">
                          <label>Address</label>
                          <textarea className="form-control video-desc" value={formData.address} readOnly />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-field">
                          <label>Age</label>
                          <input type="text" name="age" className="form-control" value={formData.age} readOnly />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-field">
                          <label>Fees</label>
                          <input type="text" name="fees" className="form-control" value={formData.fees} readOnly />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field">
                          <label>Appointment Date</label>
                          <input type="date" name="appointmentDate" className="form-control" value={formData.appointmentDate} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field">
                          <label>Time</label>
                          <input type="time" name="time" className="form-control" value={formData.time} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field">
                          <label>Appointment Status</label>
                          <input type="text" name="appointmentStatus" className="form-control" value={formData.appointmentStatus} readOnly />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field">
                          <label>Payment Method</label>
                          <input type="text" name="paymentMethod" className="form-control" value={formData.paymentMethod} readOnly />
                        </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-field">
                            <label>Dr Name</label>
                            <input type="text" name="doctorName" className="form-control" value={formData.doctorName} readOnly />
                          </div>
                        </div>
                        <div className="col-sm-3 ps-5">
                          <div className="input-field">
                            <label>Status</label>
                            <div className="barcode-range-inner-field">
                              <p>Apointment Status</p>
                              <div className="status-toggle">
                                <div className="check-box">
                                  <input type="checkbox" defaultChecked />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>          
                      </div>
                      <button className="submit-green-btn">SUBMIT</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default AppointmentUpdate;
