import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PetServices from '../services/PetServices';
import AnimalCategoryServices from '../services/AnimalServices';


function AddSellAnimal() {
  const [formData, setFormData] = useState({
    petName: '',
    petBreed: '',
    age: {
      year: '',
      month: ''
    },
    gender: '',
    weight: '',
    dateOfBirth: '',
    frontImage: null,
    endImage: null,
    sideImage: null,
    category: '',
  });

  const [categories, setCategories] = useState([]);
  const [breeds, setBreeds] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await AnimalCategoryServices.getAnimalCategory();
        if (res.status === true) {
          setCategories(res.data);
        } else {
          alert('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Failed to fetch categories', error);
        alert('Failed to fetch categories');
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = async (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      category: value,
      petBreed: '', // reset petBreed when category changes
    });

    if (value) {
      try {
        const res = await AnimalCategoryServices.getBreedByCategory(value);
        if (res.status === true) {
          setBreeds(res.data);
        } else {
          alert('Failed to fetch breeds');
        }
      } catch (error) {
        console.error('Failed to fetch breeds', error);
        alert('Failed to fetch breeds');
      }
    } else {
      setBreeds([]);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const [mainKey, subKey] = name.split('.');

    if (files) {
      if (files.length === 3) {
        setFormData({
          ...formData,
          frontImage: files[0],
          endImage: files[1],
          sideImage: files[2]
        });
      } else {
        alert('Please select exactly 3 images.');
      }
    } else if (subKey) {
      setFormData({
        ...formData,
        [mainKey]: {
          ...formData[mainKey],
          [subKey]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData1 = new FormData();
    for (const key in formData) {
      if (typeof formData[key] === 'object' && formData[key] !== null && !(formData[key] instanceof File)) {
        for (const subKey in formData[key]) {
          formData1.append(`${key}.${subKey}`, formData[key][subKey]);
        }
      } else {
        formData1.append(key, formData[key]);
      }
    }
    console.log('Form data submitted:', formData1);
    try {
      const res = await PetServices.addSellAnimal(formData1);
      console.log(res);
      if (res.status === true) {
        alert('Animal Added successfully');
        setFormData({
          petName: '',
          petBreed: '',
          age: {
            year: '',
            month: ''
          },
          gender: '',
          weight: '',
          dateOfBirth: '',
          frontImage: null,
          endImage: null,
          sideImage: null,
          category: '',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to add Animal details');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Animal Sell</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/sellAnimalList">Animal List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="input-field">
                    <label>Select Category <span className="red">*</span></label>
                    <select
                      name="category"
                      className="form-control"
                      value={formData.category}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-field">
                    <label htmlFor="petBreed">Pet’s Breed</label>
                    <select
                      name="petBreed"
                      id="petBreed"
                      className="form-control"
                      value={formData.petBreed}
                      onChange={handleChange}
                      disabled={!formData.category}
                    >
                      <option value="">Select Breed</option>
                      {breeds.map((breed) => (
                        <option key={breed._id} value={breed._id}>
                          {breed.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-field">
                    <label htmlFor="petName">Pet’s Name</label>
                    <input
                      type="text"
                      name="petName"
                      id="petName"
                      className="form-control"
                      value={formData.petName}
                      onChange={handleChange}
                      placeholder="Enter Pet’s Name"
                    />
                  </div>
                </div><div className="col-sm-2">
                  <div className="input-field">
                    <label>Age (Years)</label>
                    <input
                      type="number"
                      name="age.year"
                      className="form-control"
                      value={formData.age.year}
                      onChange={handleChange}
                      placeholder="Age in Years"
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="input-field">
                    <label>Age (Months)</label>
                    <input
                      type="number"
                      name="age.month"
                      className="form-control"
                      value={formData.age.month}
                      onChange={handleChange}
                      placeholder="Age in Months"
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="input-field">
                    <label htmlFor="gender">Gender</label>
                    <select
                      name="gender"
                      id="gender"
                      className="form-control"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="input-field">
                    <label htmlFor="weight">Weight in Kg</label>
                    <input
                      type="text"
                      name="weight"
                      id="weight"
                      className="form-control"
                      value={formData.weight}
                      onChange={handleChange}
                      placeholder="Enter Weight in Kg"
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="input-field">
                    <label htmlFor="dateOfBirth">Date of Birth</label>
                    <input
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      className="form-control"
                      value={formData.dateOfBirth}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                
                <div className="col-md-6">
                <div className="col-md-6">
                  <div className="input-field">
                    <label>Animal Photos</label>
                    <div className="animal-img">
                      <input
                        type="file"
                        name="images"
                        className="form-control"
                        multiple
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <button type="submit" className="submit-green-btn">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSellAnimal;
