import React, { useState } from 'react';
import useAsync from '../hooks/useAsync';
import AppointmentServices from '../services/AppointmentServices';
import HelpTogal from './TogelButton/HelpTogal';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import { Link } from 'react-router-dom';
import AppointmentUpdate from './Update Model/ApointmentUpdate';
import ApointmentStatus from './TogelButton/ApointmentStatus';
import { utils, writeFile } from 'xlsx';

Modal.setAppElement('#root');

function Apointment() {
  const { data, error, isLoading, run } = useAsync(AppointmentServices.getApointment);
  const count = data?.data?.length;
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleEditDetails = (help) => {
    setSelectedEdit(help);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (help) => {
    setSelectedEdit(help);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = data?.data?.filter(user =>
    (user?.farmer?.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user?.doctor?.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user?.doctor?.fee?.toString() || '').includes(searchQuery) ||
    (user.farmer?.age?.toString() || '').includes(searchQuery) ||
    (user.address?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.state?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (user.district?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;
  }

  function formatTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedTime;
  }

  function downloadAppointments() {
    const formattedData = data?.data?.map((appointment, index) => ({
      '#': index + 1,
      Image: process.env.REACT_APP_URL + appointment?.farmer?.image,
      Name: appointment?.farmer?.name,
      Age: appointment?.farmer?.age,
      Fee: appointment?.doctor?.fee,
      Date: formatDateTime(appointment.duration.date),
      Time: formatTime(appointment.duration.date),
      Address: appointment?.farmer?.address,
      'Dr Name': appointment?.doctor?.name,
      'Complete Status': appointment?.completeStatus // Assuming there's a field called completeStatus
    }));

    const worksheet = utils.json_to_sheet(formattedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Appointments');
    writeFile(workbook, 'Appointments.xlsx');
  }

  return (
    <>
      <div className="right_col" role="main">
        <div className="title-box">
          <h2>Appointment List <span className="badge bg-orange">{count}</span></h2>
        </div>
        <div className="sub-title-box">
          <div className="sub-title-box-left">
            <p>Appointment</p>
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn" onClick={downloadAppointments}><img src="img/excel.svg" alt="Download" />Download</button>
          </div>
        </div>
        <div className="container-box px-0">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input 
                type="search" 
                name="search" 
                placeholder="Search" 
                value={searchQuery} 
                onChange={handleSearch} 
              />
              <button className="search-btn">Search</button>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Fee</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Address</th>
                  <th>Dr Name</th>
                  <th>Complete Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.map((apointment, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="product-img">
                        <img src={process.env.REACT_APP_URL + apointment?.farmer?.image} alt="" style={{ height: '50px', width: '50px', objectFit: 'contain' }}  />
                      </div>
                    </td>
                    <td>{apointment?.farmer?.name}</td>
                    <td>{apointment?.farmer?.age}</td>
                    <td>{apointment?.doctor?.fee}</td>
                    <td className="payment">{formatDateTime(apointment.duration.date)}</td>
                    <td>{formatTime(apointment.duration.date)}</td>
                    <td>{apointment?.farmer?.address}</td>
                    <td>{apointment?.doctor?.name}</td>
                    <td className="status-toggle">
                      <ApointmentStatus user={apointment} onSuccess={run} />
                    </td>
                    <td>
                      <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(apointment)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(apointment)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <AppointmentUpdate selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="apointment" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  );
}

export default Apointment;
