import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './component/Auth/Login';
import Dashboard from './component/Dashboard';
import Header from './component/Header/Header';
import Footer from './component/Header/Footer';
import SideBar from './component/Header/SideBar';
import UserManager from './component/UserManager';
import UserDoctor from './component/UserDoctor';
import Benifit from './component/Benifit';
import Contect from './component/Contect';
import Enquiry from './component/Enquiry';
import Help from './component/Help';
import HomeSlider from './component/HomeSlider';
import Plans from './component/Plans';
import Subscritption from './component/Subscritption';
import Notification from './component/Notification';
import PushNotification from './component/pushNotification';
import PushNotificationList from './component/PushNotificationList';
import Settings from './component/Settings';
import Apointment from './component/Appointment';
import Feedback from './component/Feedback';
import AdminUser from './component/AdminUser';
import AddHomeSlider from './component/Add Model/AddHomeSlider';
import AddAdmin from './component/Add Model/AddAdmin';
import AddNotification from './component/Add Model/AddNotification';
import AddCommunityCategory from './component/Add Model/AddCommunityCategory';
import AddAnimalCategory from './component/Add Model/AddAnimalCategory';
import AddBreed from './component/Add Model/AddBreed';
import AddSubscription from './component/Add Model/AddSubscription';
import AddPlan from './component/Add Model/AddPlan';
import AddFaq from './component/Add Model/AddFaq';
import AddEducation from './component/Add Model/AddEducation';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermCondition from './component/TermAndCondition';
import AboutUs from './component/AboutUs';
import AddPet from './component/AddPet';
import Breed from './component/Breed';
import ViewAllPetList from './component/ViewAllPetList';
import AddProduct from './component/AddProduct';
import ProductList from './component/ProductList';
import AddSellAnimal from './component/AddSellAnimal';
import SellAnimalList from './component/SellAnimalList';
import CategoryList from './component/CategoryList';
import AddProductCategory from './component/AddProductCategory';
import AddBanner from './component/Add Model/AddBanner';
import Banner from './component/Banner';
import Faq from './component/Faq';
import Education from './component/Education';
import Order from './component/Order';
import OrderPending from './component/OrderPending';
import OrderConfirm from './component/OrderConfirm';
import OrderCancle from './component/OrderCancle';
import OrderDelivered from './component/OrderDelivered';
import OrderOutForDelivery from './component/OrderOutForDelivery';
import GenrateInvoice from './component/Button/GenrateInvoice';
import Earning from './component/Earning';
import HowToImage from './component/HowToImage';
import CommunityCategory from './component/CommunityCategory';
import AnimalCategory from './component/AnimalCategory';
import Verification from './component/Verification';
import UserPermission from './component/UserPermission';
import Home from './component/Home';
import Refund from './component/Refund';
import Terms from './component/Terms';
import Privacy from './component/Privacy';
import Layout from './Layout';

const PrivateRoute = ({ children, isAuthenticated, requiredPermission, isAdmin }) => {
  const permissions = JSON.parse(localStorage.getItem('userPermissions')) || {};

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or any other loading indicator
  }
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  if (!isAdmin && !permissions[requiredPermission]) {
    return <Navigate to="/dashboard" />; // Or an appropriate "not authorized" page
  }
  return children;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const userRole = localStorage.getItem('userRole');
    if (token && userRole) {
      setIsAuthenticated(true);
      setRole(userRole);
      setIsAdmin(userRole === 'Admin');
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <Router>
      <Layout isAuthenticated={isAuthenticated} role={role}>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Login setIsAuthenticated={setIsAuthenticated} setRole={setRole} />
          }
        />
        <Route
          path=""
          element={
           <Home />
          }
        />
         <Route
          path="/refund"
          element={
           <Refund />
          }
        />
         <Route
          path="/terms"
          element={
           <Terms/>
          }
        />
        <Route
          path="/privacy"
          element={
           <Privacy/>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="dashboard" isAdmin={isAdmin}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-permission"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="userPermission" isAdmin={isAdmin}>
              <UserPermission />
            </PrivateRoute>
          }
        />
        <Route
          path="/usermanager"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="userManager" isAdmin={isAdmin}>
              <UserManager />
            </PrivateRoute>
          }
        />
        <Route
          path="/userdoctor"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="userDoctor" isAdmin={isAdmin}>
              <UserDoctor />
            </PrivateRoute>
          }
        />
        <Route
          path="/benefit"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="benefit" isAdmin={isAdmin}>
              <Benifit />
            </PrivateRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="contact" isAdmin={isAdmin}>
              <Contect />
            </PrivateRoute>
          }
        />
        <Route
          path="/apointment"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="apointment" isAdmin={isAdmin}>
              <Apointment />
            </PrivateRoute>
          }
        />
        <Route
          path="/feedback"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="feedback" isAdmin={isAdmin}>
              <Feedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="enquiry" isAdmin={isAdmin}>
              <Enquiry />
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="help" isAdmin={isAdmin}>
              <Help />
            </PrivateRoute>
          }
        />
        <Route
          path="/homeslider"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="homeslider" isAdmin={isAdmin}>
              <HomeSlider />
            </PrivateRoute>
          }
        />
        <Route
          path="/notification"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="notification" isAdmin={isAdmin}>
              <Notification />
            </PrivateRoute>
          }
        />
        <Route
          path="/pushNotification"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="pushNotification" isAdmin={isAdmin}>
              <PushNotification />
            </PrivateRoute>
          }
        />
        <Route
          path="/push-notification-list"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="pushNotificationList" isAdmin={isAdmin}>
              <PushNotificationList />
            </PrivateRoute>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="privacyPolicy" isAdmin={isAdmin}>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path="/term-condition"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="termCondition" isAdmin={isAdmin}>
              <TermCondition />
            </PrivateRoute>
          }
        />
        <Route
          path="/about-us"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="aboutUs" isAdmin={isAdmin}>
              <AboutUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="plans" isAdmin={isAdmin}>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="subscription" isAdmin={isAdmin}>
              <Subscritption />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="settings" isAdmin={isAdmin}>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminuser"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="adminUser" isAdmin={isAdmin}>
              <AdminUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/addHomeSlider"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addHomeSlider" isAdmin={isAdmin}>
              <AddHomeSlider />
            </PrivateRoute>
          }
        />
        <Route
          path="/addAdmin"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addAdmin" isAdmin={isAdmin}>
              <AddAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/addNotification"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addNotification" isAdmin={isAdmin}>
              <AddNotification />
            </PrivateRoute>
          }
        />
        <Route
          path="/addCommunityCategory"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addCommunityCategory" isAdmin={isAdmin}>
              <AddCommunityCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/addAnimalCategory"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addAnimalCategory" isAdmin={isAdmin}>
              <AddAnimalCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/addBreed"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addBreed" isAdmin={isAdmin}>
              <AddBreed />
            </PrivateRoute>
          }
        />
        <Route
          path="/addSubscription"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addSubscription" isAdmin={isAdmin}>
              <AddSubscription />
            </PrivateRoute>
          }
        />
        <Route
          path="/addPlan"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addPlan" isAdmin={isAdmin}>
              <AddPlan />
            </PrivateRoute>
          }
        />
        <Route
          path="/addFaq"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addFaq" isAdmin={isAdmin}>
              <AddFaq />
            </PrivateRoute>
          }
        />
        <Route
          path="/addEducation"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addEducation" isAdmin={isAdmin}>
              <AddEducation />
            </PrivateRoute>
          }
        />
        <Route
          path="/addPet"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addPet" isAdmin={isAdmin}>
              <AddPet />
            </PrivateRoute>
          }
        />
        <Route
          path="/breed"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="breed" isAdmin={isAdmin}>
              <Breed />
            </PrivateRoute>
          }
        />
        <Route
          path="/view-all-pet-list"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="viewAllPetList" isAdmin={isAdmin}>
              <ViewAllPetList />
            </PrivateRoute>
          }
        />
        <Route
          path="/addProduct"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addProduct" isAdmin={isAdmin}>
              <AddProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/productList"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="productList" isAdmin={isAdmin}>
              <ProductList />
            </PrivateRoute>
          }
        />
        <Route
          path="/addSellAnimal"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addSellAnimal" isAdmin={isAdmin}>
              <AddSellAnimal />
            </PrivateRoute>
          }
        />
        <Route
          path="/sellAnimalList"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="sellAnimalList" isAdmin={isAdmin}>
              <SellAnimalList />
            </PrivateRoute>
          }
        />
        <Route
          path="/categoryList"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="categoryList" isAdmin={isAdmin}>
              <CategoryList />
            </PrivateRoute>
          }
        />
        <Route
          path="/addProductCategory"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addProductCategory" isAdmin={isAdmin}>
              <AddProductCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/addBanner"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="addBanner" isAdmin={isAdmin}>
              <AddBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/banner"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="banner" isAdmin={isAdmin}>
              <Banner />
            </PrivateRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="faq" isAdmin={isAdmin}>
              <Faq />
            </PrivateRoute>
          }
        />
        <Route
          path="/education"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="education" isAdmin={isAdmin}>
              <Education />
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="order" isAdmin={isAdmin}>
              <Order />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-pending"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="orderPending" isAdmin={isAdmin}>
              <OrderPending />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-confirm"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="orderConfirm" isAdmin={isAdmin}>
              <OrderConfirm />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-cancel"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="orderCancel" isAdmin={isAdmin}>
              <OrderCancle />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-delivered"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="orderDelivered" isAdmin={isAdmin}>
              <OrderDelivered />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-out-for-delivery"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="orderOutForDelivery" isAdmin={isAdmin}>
              <OrderOutForDelivery />
            </PrivateRoute>
          }
        />
        <Route
          path="/genrate-invoice"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="genrateInvoice" isAdmin={isAdmin}>
              <GenrateInvoice />
            </PrivateRoute>
          }
        />
        <Route
          path="/earning"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="earning" isAdmin={isAdmin}>
              <Earning />
            </PrivateRoute>
          }
        />
        <Route
          path="/howtoimage"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="howToImage" isAdmin={isAdmin}>
              <HowToImage />
            </PrivateRoute>
          }
        />
        <Route
          path="/community-category"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="communityCategory" isAdmin={isAdmin}>
              <CommunityCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/animal-category"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="animalCategory" isAdmin={isAdmin}>
              <AnimalCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/verification"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated} requiredPermission="verification" isAdmin={isAdmin}>
              <Verification />
            </PrivateRoute>
          }
        />
      </Routes>
      </Layout>
    </Router>
  );
}

export default App;
