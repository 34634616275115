import React,{useState,useEffect} from 'react'
import AdminServices from '../../services/AdminServices';

function UpdateAdmin({admin , closeModal , onSuccess}) {

  const [formValues, setFormValues] = useState({
    name: '',
    role: '',
    email: '',
    mobileNo: '',
    city: '',
    date: '',
    pincode: '',
    password: '',
  });
  useEffect(() => {
    if (admin) {
      setFormValues({
        name: admin?.name,
        role: admin?.role,
        email: admin?.email,
        mobileNo: admin?.mobileNo,
        city: admin?.city,
        date: admin?.date,
        pincode: admin?.pincode,
        password: admin?.password,
      });
    }
  }, [admin]);

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

 

//   // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        formData.append(key, formValues[key]);
      }
      console.log(formValues)
      await AdminServices.updateAdmin(admin.id,formData); 
      alert('Admin details updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Failed to update admin details', error);
      alert('Failed to update admin details');
    }
  };


    return (
      <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Admin Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-lg-8 col-md-6">
                  <div className="input-field">
                    <label>Name</label>
                    <input type="text" name="name" className="form-control"  value={formValues.name} 
                      onChange={handleInputChange}  placeholder="New notification" />
                  </div>
                
                
                </div>
                <div className="col-lg-4 col-md-6">
                <div className="input-field">
                    <label>User Type</label>
                      <select className="form-control" value={formValues.role} 
                        onChange={handleInputChange} name="role">
                          <option value= "">--Select Type--</option>
                        <option value="Admin">Admin</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Helper">Helper</option>
                        <option value="Doctor">Doctor</option>
                      </select>
                    </div>
                </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Email</label>
                          <input  type="text" name="email"  value={formValues.email} 
                      onChange={handleInputChange} className="form-control"  />
                        </div>
                      </div>
                      
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Phone</label>
                          <input type="number" name="mobileNo"  value={formValues.mobileNo} 
                      onChange={handleInputChange} className="form-control"  />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>City</label>
                          <input  type="text" name="city"  value={formValues.city} 
                      onChange={handleInputChange} className="form-control"  />
                        </div>
                      </div>
                      
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Date</label>
                          <input type="date" name="date"  value={formValues.date} 
                      onChange={handleInputChange} className="form-control"  />
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Pincode</label>
                          <input type="number" name="pincode"  value={formValues.pincode} 
                      onChange={handleInputChange} className="form-control"  />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Password</label>
                          <input type="password" name="password"  value={formValues.password} 
                      onChange={handleInputChange} className="form-control"  />
                        </div>
                      </div>
                     
                    </div>
                    <button className="submit-green-btn">Update Admin</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateAdmin