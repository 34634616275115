import React, { useState } from 'react';
import UserServices from '../../services/UserServices';
const UserStatus = ({ user,page ,onSuccess}) => {
  // Initialize the state based on the user's status
  const [isChecked, setIsChecked] = useState(user?.status === 'active');

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = !isChecked ? 'active' : 'Inactive';
    setIsChecked(!isChecked);
    if(page === 'farmer'){
      const res = await UserServices.updateFarmer(user._id, {
        status: newStatus
      });

      onSuccess();
    }else{
      const res = await UserServices.updateDoctor(user._id, {
        status: newStatus
      });

      onSuccess();
    }  
  };

  return (
   
      <div className="check-box">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
      </div>
    
  );
};

export default UserStatus;
