import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import AdminServices from '../../services/AdminServices'
function AddAdmin() {

   
  const [formValues, setFormValues] = useState({
    name: '',
    role: '',
    email: '',
    mobileNo: '',
    city: '',
    date:'',
    pincode: '',
    password: '',
  });


  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

 
//   // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
     
      await AdminServices.createAdmin(formValues); 
      alert('Admin details updated successfully');
      } catch (error) {
      console.error('Failed to update admin details', error);
    }
  };


  return (
    <>
     <div className="right_col" role="main">
        <div className="title-box">
          <h2>Admin User Add</h2>
          <div className="container-box-top-header-right">
            <Link className="list-banner-btn" to="/adminuser">User List</Link>
          </div>
        </div>
        <div className="container-box px-5">
          <div className="container-box-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="input-field">
                    <label>User Type</label>
                      <select className="form-control" value={formValues.role} 
                        onChange={handleInputChange} name="role">
                          <option value= "">--Select Type--</option>
                        <option value="Admin">Admin</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Helper">Helper</option>
                        <option value="Doctor">Doctor</option>
                      </select>
                    </div>
                    <div className="input-field">
                      <label>Name</label>
                      <input type="text" name="name" value={formValues.name} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <div className="input-field">
                      <label>Email</label>
                      <input type="text" name="email" value={formValues.email} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <div className="input-field">
                      <label>Mobile No.</label>
                      <input type="text" name="mobileNo" value={formValues.mobileNo} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <div className="input-field">
                      <label>City</label>
                      <input type="text" name="city" value={formValues.city} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    {/* <div className="input-field">
                      <label>Date</label>
                      <input type="date" name="date"value={formValues.date} 
                        onChange={handleInputChange} className="form-control" />
                    </div> */}
                    <div className="input-field">
                      <label>Pincode</label>
                      <input type="number" name="pincode" value={formValues.pincode} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <div className="input-field">
                      <label>Password</label>
                      <input type="text" name="password" value={formValues.password} 
                        onChange={handleInputChange} className="form-control" />
                    </div>
                    <button className="submit-green-btn">SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddAdmin