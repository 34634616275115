import React, { useState,useEffect } from 'react';
import SelectOrderStatus from './TogelButton/SelectOrderStatus';
import ViewOrder from './View Model/ViewOrder';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import OrderServices from '../services/OrderServices';
import useAsync from '../hooks/useAsync';
import GenrateInvoice from './Button/GenrateInvoice';
import { Link } from 'react-router-dom';


Modal.setAppElement('#root');
const initialOrders = [
  {
    id: 1,
    userName: 'Ayush Raman',
    productName: 'Pedigree',
    price: 500,
    orderId: '1115615',
    address: 'Jaipur',
    date: '04-may-2023',
    orderStatus: 'Pending',
    status: true,
  },
  // Add more initial orders as needed
];

function Order() {
  const [orders, setOrders] = useState(initialOrders);
  const navigate = useNavigate();
  const {data,error,isLoading,run} = useAsync(OrderServices.getOrders);
  console.log(data)
  const count = data?.data?.length
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderData, setOrderData] = useState(data?.data || []);
  

  useEffect(() => {
    if (data?.data) {
      setOrderData(
        data.data.filter(order =>
          order?.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order?.orderId?.toString().includes(searchTerm)
        )
      );
    }
  }, [data, searchTerm]);

  const closeInvoiceModal = () => {
    setIsInvoiceModalOpen(false);
    setInvoiceData(null);
  };
  
  const handleEditDetails = (slider) => {
    setSelectedEdit(slider);
    setIsEditModalOpen(true);
    toggleActionMenu(null)
  };

  const handleDelete = (slider) => {
    setSelectedEdit(slider);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null)
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  const handleToggleStatus = id => {
    setOrders(prevOrders =>
      prevOrders.map(order =>
        order.id === id ? { ...order, status: !order.status } : order
      )
    );
  };

  

  const handleInvoice = (order) => {
    navigate('/invoice', { state: { order } });
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>
          Order<span className="badge bg-orange">{count}</span>
        </h2>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
        <div className="container-box-top-header-left-2">
              <input 
                type="search" 
                name="search" 
                placeholder="Search" 
                value={searchTerm} 
                onChange={handleSearchChange} 
              />
              <button className="search-btn">Search</button>
            </div>
          <div className="sub-title-box-right">
            <button className="excel-btn">
              <img src="img/excel.svg" alt="Download" />Download
            </button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                {/* <th>PRODUCT NAME</th> */}
                <th>Price</th>
                <th>Order Id</th>
                <th>Address</th>
                <th>Date</th>
                <th>Order Status</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orderData?.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>{order.customerName}</td>
                {/* <td className="address">{order.orderItems.map((item) => item.productName)}</td> */}
                  <td>₹{order?.totalAmount}</td>
                  <td>{order?.orderId}</td>
                  <td>{order?.deliveryDetails?.address}</td>
                  <td>{order?.createdAt}</td>
                  <td>
                    <SelectOrderStatus data={order} page={"Order"} />
                  </td>
                  <td className="status-toggle">
                  <button className='btn btn-success' onClick={() => handleInvoice(order)}>Invoice</button>
               
                  </td>
                  <td>
                  <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(order)} >View</button></li>
                              <li><button className="reject" onClick={() => handleDelete(order)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewOrder order={selectedEdit} closeModal={closeEditModal} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="HomeSlider" closeModal={closeDeleteModal}  />
        </Modal>
        <Modal
          isOpen={isInvoiceModalOpen}
          onRequestClose={closeInvoiceModal}
          contentLabel="Generate Invoice"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <GenrateInvoice data={invoiceData} onClose={closeInvoiceModal} />
        </Modal>
    </div>
  );
}

export default Order;
