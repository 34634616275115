import React, { useState, useEffect } from 'react';
import BenefitServices from '../../services/BenefitServices';

function BenifitUpdate({ Benifit, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    message: '',
    process: '',
    fee: 0,
    days: 0,
    status: 'active',
  });

  useEffect(() => {
    if (Benifit) {
      setFormValues({
        message: Benifit?.message || '',
        process: Benifit?.process || '',
        fee: Benifit?.fee || 0,
        days: Benifit?.days || 0,
        status: Benifit?.status || 'active',
      });
    }
  }, [Benifit]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await BenefitServices.updateBenefit(Benifit?._id, formValues);
      alert('Benefit updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Failed to update Benefit', error);
      alert('Failed to update Benefit');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Benefit Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Message</label>
                          <textarea
                             style={{ height: '100px' }}
                            className="form-control"
                            name="message"
                            value={formValues.message}
                            onChange={handleInputChange}
                            rows="2"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Process</label>
                          <textarea
                           style={{ height: '100px' }}
                            className="form-control"
                            name="process"
                            value={formValues.process}
                            onChange={handleInputChange}
                            rows="2"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Fee</label>
                          <input
                            type="number"
                            className="form-control"
                            name="fee"
                            value={formValues.fee}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Days</label>
                          <input
                            type="number"
                            className="form-control"
                            name="days"
                            value={formValues.days}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="input-field">
                          <label>Status</label>
                          <select
                            className="form-control"
                            name="status"
                            value={formValues.status}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="submit-green-btn">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenifitUpdate;
