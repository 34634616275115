import React, { useState } from 'react';
import ProductServices from '../services/ProductServices';
import useAsync from '../hooks/useAsync';
import { Link } from 'react-router-dom';

function AddProduct() {
  const { data, error, isLoading, run } = useAsync(ProductServices.getAllCategory);

  const [formValues, setFormValues] = useState({
    category: '',
    name: '',
    description: '',
    weight: [{ value: '', price: '', mrp: '' }], // Updated to handle multiple entries
    gst: '',
    images: [], // Updated to handle multiple files
    status: 'active',
  });

  const [previewImages, setPreviewImages] = useState(['img/placeholder-img.png']); // Updated for multiple previews

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleArrayChange = (index, event) => {
    const { name, value } = event.target;
    const updatedWeight = formValues.weight.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormValues({
      ...formValues,
      weight: updatedWeight,
    });
  };

  const handleAddFields = () => {
    setFormValues({
      ...formValues,
      weight: [...formValues.weight, { value: '', price: '', mrp: '' }],
    });
  };

  const handleRemoveFields = (index) => {
    const updatedWeight = formValues.weight.filter((_, i) => i !== index);
    setFormValues({
      ...formValues,
      weight: updatedWeight,
    });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFormValues({
      ...formValues,
      images: files,
    });

    const previewFiles = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(previewFiles).then((images) => {
      setPreviewImages(images);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        if (key === 'images') {
          formValues[key].forEach((image) => {
            formData.append('images', image); // Append each image file
          });
        } else if (key === 'weight') {
          formValues[key].forEach((item, index) => {
            formData.append(`weight[${index}][value]`, item.value);
            formData.append(`weight[${index}][price]`, item.price);
            formData.append(`weight[${index}][mrp]`, item.mrp);
          });
        } else {
          formData.append(key, formValues[key]);
        }
      }

      const res = await ProductServices.AddProduct(formData);
      console.log(res);
      if (res.status === true) {
        alert('Product added successfully');
        setFormValues({
          category: '',
          name: '',
          description: '',
          weight: [{ value: '', price: '', mrp: '' }],
          gst: '',
          images: [],
          status: 'active',
        });
        setPreviewImages(['img/placeholder-img.png']);
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add product', error);
      alert('Failed to add product');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Product</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/productList">Product List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="input-field">
                    <label>Select Category <span className="red">*</span></label>
                    <select
                      name="category"
                      className="form-control"
                      value={formValues.category}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Category</option>
                      {data?.data?.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="input-field">
                    <label>Product Name <span className="red">*</span></label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={formValues.name}
                      onChange={handleInputChange}
                      placeholder="Product"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Description</label>
                    <textarea
                      name="description"
                      className="form-control video-desc"
                      value={formValues.description}
                      onChange={handleInputChange}
                      placeholder="Description"
                    />
                  </div>
                </div>

                {formValues.weight.map((item, index) => (
                  <div key={index} className="row">
                    <div className="col-sm-3">
                      <div className="input-field">
                        <label>Product Weight <span className="red">*</span></label>
                        <input
                          type="text"
                          name="value"
                          className="form-control"
                          value={item.value}
                          onChange={(event) => handleArrayChange(index, event)}
                          placeholder="1Kg"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="input-field">
                        <label>Price <span className="red">*</span></label>
                        <input
                          type="text"
                          name="price"
                          className="form-control"
                          value={item.price}
                          onChange={(event) => handleArrayChange(index, event)}
                          placeholder="₹500"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="input-field">
                        <label>MRP <span className="red">*</span></label>
                        <input
                          type="text"
                          name="mrp"
                          className="form-control"
                          value={item.mrp}
                          onChange={(event) => handleArrayChange(index, event)}
                          placeholder="₹500"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 d-flex align-items-center">
                      <div className="input-field">
                        
                        <button
                          type="button"
                          onClick={() => handleRemoveFields(index)}
                          className="btn btn-danger"
                        >
                         Remove
                        </button>&nbsp; &nbsp;
                        <button
                          type="button"
                          onClick={handleAddFields}
                          className="btn btn-success ml-2"
                        >
                          Add variant
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>GST</label>
                    <input
                      type="text"
                      name="gst"
                      className="form-control"
                      value={formValues.gst}
                      onChange={handleInputChange}
                      placeholder="GST percentage"
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Upload<span className="red">*</span></label>
                    <input
                      type="file"
                      name="images"
                      className="form-control"
                      multiple
                      onChange={handleFileChange}
                      required
                    />
                    <p>
                      <span>Drop images or Paste URLs (up to resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp
                    </p>
                    <div className="file-preview text-center">
                      {previewImages.map((image, index) => (
                        <img key={index} src={image} alt={`preview ${index}`} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
