
import React from 'react';

const Privacy = () => {
  return (
    <div>
      <div className="container body" id="root">
        <div>
          <div className="privacy-policy  px-5 py-3">
            <h1 className='mb-3'>Privacy Policy</h1>
            <p className='mb-2'><strong>Privacy Policy for Pashuchikitsa</strong></p>
            <p className='mb-2'>Effective Date: 26/07/2024</p>
            <p className='mb-2'>At the Pashuchikitsa, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://pashuchikitsa.com/">Pashuchikitsa.com</a> or use our services.</p>
            
            <h6>1. Information We Collect</h6>
            <ul className='ps-3 mb-3'>
              <li><strong>Personal Data:</strong> Name, email address, phone number, etc.</li>
              <li><strong>Usage Data:</strong> IP address, browser type, operating system, pages visited, etc.</li>
              <li><strong>Cookies:</strong> To enhance user experience and track website usage.</li>
            </ul>
            
            <h6>2. How We Use Your Information</h6>
            <ul className='ps-3 mb-3'>
              <li>To provide, operate, and maintain our website and services.</li>
              <li>To improve, personalize, and expand our website and services.</li>
              <li>To communicate with you, including customer service and updates.</li>
              <li>To process transactions and send you related information.</li>
            </ul>
            
            <h6 >3. Sharing Your Information</h6>
            <p className='mb-3'>We do not sell, trade, or otherwise transfer your Personally Identifiable Information to outside parties except to trusted third parties who assist us in operating our website and conducting our business.</p>
            
            <h6>4. Security</h6>
            <p className='mb-3'>We implement a variety of security measures to maintain the safety of your personal information.</p>
            
            <h6>5. Your Rights</h6>
            <p>You have the right to access, correct, or delete your personal information. You can contact us to exercise these rights.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;



  
    
 

  
