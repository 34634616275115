import React, { useState, useEffect } from "react";
import UserServices from "../../services/UserServices";

function ViewVerification({ verification, closeModal }) {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await UserServices.getAllDoctor();
        const filteredDoctors = response.data.filter(
          (doctor) => doctor.city === verification?.user?.city
        );
        setDoctors(filteredDoctors);
      } catch (error) {
        console.error("Failed to fetch doctors:", error);
      }
    };

    if (verification?.user?.city) {
      fetchDoctors();
    }
  }, [verification]);

  const handleAssign = async () => {
    try {
      await UserServices.assignVerificationToDoctor(verification._id, selectedDoctor);
      closeModal();
    } catch (error) {
      console.error("Failed to assign verification:", error);
    }
  };

  return (
    <div
      className="modal fade viewbox edit-box show d-block"
      id="customerModal"
      tabIndex={-1}
      aria-labelledby="customerModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="customerModal">
              Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            />
          </div>
          <div className="modal-body px-5">
            <div className="d-box mt-3 pt-1">
              <ul className="d-flex list-unstyled justify-content-between">
                <li>
                  <span>User Name: </span>
                  {verification?.user?.name}
                </li>
                <li>
                  <span>Verification Fee: </span> ₹{verification?.fee}
                </li>
                <li>
                  <span>User Address: </span> {verification?.user?.address}
                </li>
                <li>
                  <span>User District: </span> {verification?.user?.district}
                </li>
                <li>
                  <span>User City: </span> {verification?.user?.city}
                </li>
                <li>
                  <span>User Mobile no: </span> {verification?.user?.mobileNo}
                </li>
                <li>
                  <span>Pet Name: </span> {verification?.sellAnimal?.petName}
                </li>
                <li>
                  <span>Pet Type: </span> {verification?.sellAnimal?.category.name}
                </li>
                <li>
                  <span>Pet Age: </span> {verification?.sellAnimal?.age?.year} year
                </li>
                <li>
                  <span>Verification Address: </span> {verification?.address}
                </li>
                <li>
                  <span>Notes: </span> {verification?.note}
                </li>
                <li>
                  <span>Date: </span>
                  {verification?.createdAt}
                </li>
              </ul>
            </div>
            <div className="doctor-dropdown mt-3">
              <label htmlFor="doctor">Select Doctor:</label>
              <select
                id="doctor"
                className="form-control"
                value={selectedDoctor}
                onChange={(e) => setSelectedDoctor(e.target.value)}
              >
                <option value="">--Select Doctor--</option>
                {doctors.map((doctor) => (
                  <option key={doctor._id} value={doctor._id}>
                    Name: {doctor.name} and Mobile No: {doctor.mobileNo}
                  </option>
                ))}
              </select>
            </div>
            <div className="assign-button mt-3">
              <button
                className="btn btn-primary"
                onClick={handleAssign}
                disabled={!selectedDoctor}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewVerification;
