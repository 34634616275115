import requests from "./httpService";

const AppointmentServices = {

    getApointment : async () =>{
       
        return requests.get(`/apointment`);
    },
    updateApointment : async (id,body) =>{
        return requests.put(`/apointment/updatestatus/${id}`,body);
    },

    createApointment  : async (body) =>{
        return requests.post(`/apointment`,body);
    },

    deleteApointment  : async (id) =>{
        return requests.delete(`/apointment/${id}`);
    }
 

}

export default AppointmentServices;