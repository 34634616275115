import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './component/Header/Header';
import Footer from './component/Header/Footer';
import SideBar from './component/Header/SideBar';


const Layout = ({ isAuthenticated, role, children }) => {
  const location = useLocation();
  const shouldShowGlobalComponents = location.pathname !== '/';

  return (
    <>
      {shouldShowGlobalComponents && isAuthenticated && <SideBar role={role} />}
      {shouldShowGlobalComponents && isAuthenticated && <Header />}
      <div>{children}</div>
      {shouldShowGlobalComponents && isAuthenticated && <Footer />}
    </>
  );
};

export default Layout;
