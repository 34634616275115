import React from 'react';

const Refund = () => {
  return (
    <div className="container body" id="root">
    <div>
      <div className="refund-policy  px-5 py-3">
        <h1 className='mb-3'>Refund Policy</h1>
        <p className='mb-2'><strong>Refund Policy for Pashuchikitsa</strong></p>
        <p className='mb-2'>Effective Date: 26/07/2024</p>

        <h6>1. Overview</h6>
        <p  className='mb-3'>Our refund policy ensures that customers are treated fairly and receive appropriate remedies when necessary. This policy outlines the conditions under which refunds may be granted.</p>

        <h6>2. Eligibility for Refunds</h6>
        <p  >Refunds will be considered under the following circumstances:</p>
        <ul className='ps-3 mb-3'>
          <li>If a product or service provided does not meet the promised standards.</li>
          <li>If there are significant defects or issues with the product or service.</li>
          <li>If the customer cancels the service within a specified period.</li>
        </ul>

        <h6>3. Process for Requesting Refunds</h6>
        <p  className='mb-3'>To request a refund, customers must contact us within 24 hours of receiving the product or service. The request should include a detailed description of the issue and any relevant supporting documentation.</p>

        <h6>4. Processing Refunds</h6>
        <p  className='mb-3'>Once a refund request is received and reviewed, we will notify the customer of the approval or rejection of the refund. Approved refunds will be processed within 42 days, and a credit will be applied to the original method of payment.</p>

        <h6>5. Changes to Refund Policy</h6>
        <p>We reserve the right to modify this refund policy at any time. Any changes will be effective immediately upon posting on our website.</p>
      </div>
    </div>
    </div>
  );
};

export default Refund;
