import requests from "./httpService";

const VerificationServices = {

    getVerification : async () =>{
        return requests.get(`/verification`);
    },
    updateVerification : async (id,body) =>{
        return requests.put(`/verification/${id}`,body);
    },
    deleteVerification : async (id) =>{
        return requests.delete(`/verification/${id}`);
    }
   


}

export default VerificationServices;