import React, { useState, useEffect } from 'react';
import PetServices from '../../services/PetServices';
import AnimalCategoryServices from '../../services/AnimalServices';

function SellAnimalUpdate({ selectedAnimal, closeModal, onSuccess }) {
  const [formData, setFormData] = useState({
    petName: '',
    category: '',
    petBreed: '',
    age: {
      year: '',
      month: ''
    },
    gender: '',
    weight: '',
    dateOfBirth: '',
    startDate: '',
    endDate: '',
    frontImage: null,
    endImage: null,
    sideImage: null,
  });

  const [previewImages, setPreviewImages] = useState({
    frontImage: 'img/placeholder-img.png',
    endImage: 'img/placeholder-img.png',
    sideImage: 'img/placeholder-img.png'
  });

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [breedOptions, setBreedOptions] = useState([]);

  const fetchCategories = async () => {
    try {
      // Replace with actual service call
      const res = await AnimalCategoryServices.getAnimalCategory();
      if (res.status === true) {
        setCategoryOptions(res.data);
      }
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  };

  const fetchBreeds = async (categoryId) => {
    try {
      const res = await AnimalCategoryServices.getBreedByCategory(categoryId);
      if (res.status === true) {
        setBreedOptions(res.data);
      }
    } catch (error) {
      console.error('Failed to fetch breeds', error);
    }
  };

  useEffect(() => {
    if (selectedAnimal) {
      setFormData({
        petName: selectedAnimal.petName,
        category: selectedAnimal.category,
        petBreed: selectedAnimal.petBreed,
        age: selectedAnimal.age,
        gender: selectedAnimal.gender,
        weight: selectedAnimal.weight,
        dateOfBirth: selectedAnimal.dateOfBirth ? formatDateTime(selectedAnimal.dateOfBirth) : '',
        startDate: selectedAnimal.startDate,
        endDate: selectedAnimal.endDate,
        frontImage: selectedAnimal.frontImage,
        endImage: selectedAnimal.endImage,
        sideImage: selectedAnimal.sideImage,
      });

      setPreviewImages({
        frontImage: selectedAnimal.frontImage ? process.env.REACT_APP_URL + selectedAnimal.frontImage : 'img/placeholder-img.png',
        endImage: selectedAnimal.endImage ? process.env.REACT_APP_URL + selectedAnimal.endImage : 'img/placeholder-img.png',
        sideImage: selectedAnimal.sideImage ? process.env.REACT_APP_URL + selectedAnimal.sideImage : 'img/placeholder-img.png'
      });
    }
  }, [selectedAnimal]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (formData.category) {
      fetchBreeds(formData.category);
    }
  }, [formData.category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [mainKey, subKey] = name.split('.');

    if (subKey) {
      setFormData({
        ...formData,
        [mainKey]: {
          ...formData[mainKey],
          [subKey]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages(prev => ({
          ...prev,
          [name]: reader.result
        }));
      };
      reader.readAsDataURL(file);
      setFormData({
        ...formData,
        [name]: file,
      });
    }
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] instanceof File) {
          formDataToSend.append(key, formData[key]);
        } else if (typeof formData[key] === 'object') {
          for (const subKey in formData[key]) {
            formDataToSend.append(`${key}.${subKey}`, formData[key][subKey]);
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }

      const res = await PetServices.updateSellAnimal(selectedAnimal.id, formDataToSend);
      if (res.status === true) {
        alert('Animal details updated successfully');
        onSuccess();
        closeModal();
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to update Animal details');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Sell Animal Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Pet’s Name</label>
                          <input
                            type="text"
                            name="petName"
                            className="form-control"
                            value={formData.petName}
                            onChange={handleChange}
                            placeholder="Enter Pet’s Name"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Pet Type<span className="red">*</span></label>
                          <select
                            name="category"
                            className="form-control"
                            value={formData.category}
                            onChange={handleChange}
                          >
                            <option value="">Select Category</option>
                            {categoryOptions.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label htmlFor="petBreed">Pet’s Breed</label>
                          <select
                            name="petBreed"
                            id="petBreed"
                            className="form-control"
                            value={formData.petBreed}
                            onChange={handleChange}
                            disabled={!formData.category}
                          >
                            <option value="">Select Breed</option>
                            {breedOptions.map((breed) => (
                              <option key={breed._id} value={breed._id}>
                                {breed.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="input-field">
                          <label>Age (Years)</label>
                          <input
                            type="number"
                            name="age.year"
                            className="form-control"
                            value={formData.age.year}
                            onChange={handleChange}
                            placeholder="Age in Years"
                          />
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="input-field">
                          <label>Age (Months)</label>
                          <input
                            type="number"
                            name="age.month"
                            className="form-control"
                            value={formData.age.month}
                            onChange={handleChange}
                            placeholder="Age in Months"
                          />
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="input-field">
                          <label>Gender</label>
                          <select
                            name="gender"
                            className="form-control"
                            value={formData.gender}
                            onChange={handleChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-field">
                          <label>Weight in Kg</label>
                          <input
                            type="text"
                            name="weight"
                            className="form-control"
                            value={formData.weight}
                            onChange={handleChange}
                            placeholder="Enter Weight in Kg"
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-field">
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            name="dateOfBirth"
                            className="form-control"
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field">
                          <label>Animal Photo</label>
                          <div className="animal-img">
                            <label>
                              <input
                                type="file"
                                name="frontImage"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                              />
                              <button type="button" className="Upload-document">
                                Front
                              </button>
                              {formData.frontImage && <img src={previewImages.frontImage} alt="Front Preview" className="img-thumbnail mt-2" />}
                            </label>
                            <label>
                              <input
                                type="file"
                                name="endImage"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                              />
                              <button type="button" className="Upload-document">
                                Back
                              </button>
                              {formData.endImage && <img src={previewImages.endImage} alt="End Preview" className="img-thumbnail mt-2" />}
                            </label>
                            <label>
                              <input
                                type="file"
                                name="sideImage"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                              />
                              <button type="button" className="Upload-document">
                                Side
                              </button>
                              {formData.sideImage && <img src={previewImages.sideImage} alt="Side Preview" className="img-thumbnail mt-2" />}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="submit-green-btn">SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellAnimalUpdate;
