import React from 'react';
import { useLocation } from 'react-router-dom';
import './Invoice.css'; // Import the CSS file

const GenrateInvoice = () => {
  const location = useLocation();
  const { order } = location.state || {};

  console.log(order);
  if (!order) {
    return <div>No order data found.</div>;
  }

  const data = order;

  const handlePrint = () => {
    const printableArea = document.getElementById('printable-area');
    const originalClasses = printableArea.className;
    
    printableArea.className += ' printable-area';
    window.print();
    printableArea.className = originalClasses;
  };

  return (
    <div id="printable-area" className="right_col show d-block">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="invoice-title">
                <h4 className="float-end font-size-15">
                  Invoice #{data?.orderId}
                  <span className="badge bg-success font-size-12 ms-2">{data?.paymentMode}</span>
                </h4>
                <div className="mb-4">
                  <h2 className="mb-1 text-muted">Pashu Mitra</h2>
                </div>
                <div className="text-muted">
                  <p className="mb-1">3184 Spruce Drive Pittsburgh, PA 15201</p>
                  <p className="mb-1">
                    <i className="uil uil-envelope-alt me-1"></i> xyz@987.com
                  </p>
                  <p>
                    <i className="uil uil-phone me-1"></i> 012-345-6789
                  </p>
                </div>
              </div>

              <hr className="my-4" />

              <div className="row">
                <div className="col-sm-6">
                  <div className="text-muted">
                    <h5 className="font-size-16 mb-3">Billed To:</h5>
                    <h5 className="font-size-15 mb-2">{data?.customerName}</h5>
                    <p className="mb-1">{data?.deliveryDetails?.address}</p>
                    <p className="mb-1">{data?.email}</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="text-muted text-sm-end">
                    <div>
                      <h5 className="font-size-15 mb-1">Invoice No:</h5>
                      <p>{data?.orderId}</p>
                    </div>
                    <div className="mt-4">
                      <h5 className="font-size-15 mb-1">Invoice Date:</h5>
                      <p>{data?.createdAt}</p>
                    </div>
                    <div className="mt-4">
                      <h5 className="font-size-15 mb-1">Order No:</h5>
                      <p>{data?.orderId}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-2">
                <h5 className="font-size-15">Order Summary</h5>

                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-centered mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: '70px' }}>No.</th>
                        <th>Item</th>
                        <th>Mrp</th>
                        <th>Price</th>
                        <th>gst</th>
                        <th>Quantity</th>
                        <th className="text-end" style={{ width: '120px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.orderItems?.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14 mb-1">{item.productName}</h5>
                              <p className="text-muted mb-0">{item.weight}</p>
                            </div>
                          </td>
                          <td>{item?.mrp}</td>
                          <td>{item.price}</td>
                          <td>{item?.gst}</td>
                          <td>{item.quantity}</td>
                          <td className="text-end">{item.totalPrice}</td>
                        </tr>
                      ))}
                      <tr>
                        <th scope="row" colSpan="6" className="text-end">Sub Total</th>
                        <td className="text-end">{data?.totalMrpAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="6" className="border-0 text-end">Discount :</th>
                        <td className="border-0 text-end">- {data?.totalMrpAmount-data?.totalAmount}</td>
                      </tr>

                      <tr>
                        <th scope="row" colSpan="6" className="border-0 text-end">Tax</th>
                        <td className="border-0 text-end">{data?.totalAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row" colSpan="6" className="border-0 text-end">Total</th>
                        <td className="border-0 text-end">
                          <h4 className="m-0 fw-semibold">{data?.totalAmount}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-print-none mt-4">
                  <div className="float-end">
                    <button onClick={handlePrint} className="btn btn-success me-1">
                      <i className="fa fa-print"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenrateInvoice;
