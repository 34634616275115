import requests from "./httpService";

const CommunityCategoryServices = {

    getCommunityCategory: async () =>{
        return requests.get(`/comucat`);
    },
    updateCommunityCategory : async (id,body) =>{
        return requests.put(`/comucat/${id}`,body);
    },

    createCommunityCategory : async (body) =>{
        console.log(body)
        return requests.post(`/comucat`,body);
    },
    deleteCommunityCategory : async (id) =>{
        return requests.delete(`/comucat/${id}`);
    }
  


}
export default CommunityCategoryServices;