import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import HomeSliderServices from '../../services/HomeSliderServices';
function AddHomeSlider() {

  
  const [formValues, setFormValues] = useState({
    title: '',
    link: '',
    sequence: '1',
    image: '',
    userFor: '',
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path



  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };
//   // Handle form submission
const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }

    await HomeSliderServices.createHomeSlider(formData);
    alert('Home slider created successfully');
  } catch (error) {
    console.error('Failed to create home slider', error);
    alert('Failed to create home slider');
  }
};

  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Home Slider Banner Add</h2>
          <div className="container-box-top-header-right">
            <Link className="list-banner-btn" to="/homeslider">List Banner</Link>
          </div>
        </div>
        <div className="container-box px-5">
          <div className="container-box-inner">
            <div className="page-details">
              <form onSubmit={handleSubmit}>
                <div className="input-field">
                  <label>Tittle</label>
                  <input type="text" name="title" value={formValues.title} 
                        onChange={handleInputChange} defaultValue="Product scanning" className="form-control" required />
                </div>
                <div className='row'>
                <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Select User</label>
                      <select className="form-control" value={formValues.userType} 
                        onChange={handleInputChange} name="userFor" required>
                        <option >Select User Type</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Doctor">Doctor</option>
                        {/* <option value="serviceCenter">Service Center</option>
                        <option value="manufacturer">manufacturer</option> */}
                      </select>
                      
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Select Sequence</label>
                      <select className="form-control" value={formValues.sequence} 
                        onChange={handleInputChange} name="sequence">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                      
                    </div>
                  </div>
                  </div>
                <div className="input-field">
                  <label>Link</label>
                  <div className="input-link-outer">
                    <input type="text" name="link"  value={formValues.link} 
                        onChange={handleInputChange} className="form-control" defaultValue="https://" />
                    <button className="link-copy-btn"><i className="fa fa-paperclip" /></button>
                  </div>
                </div>
                <div className="input-field">
                  <div className="input-field-or" />
                </div>
                <div className="input-field">
                  <label>Upload<span className="red">*</span></label>
                  <input type="file" name="image" className="form-control" onChange={handleFileChange} required />
                  <p><span>Drop an image or Paste URL (up to resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp</p>
                  <div className="file-preview text-center">
                    <img id="uploadFile" src={previewImage} alt="your image" />
                  </div>
                </div>
                <button className="submit-green-btn">SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddHomeSlider