import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProductServices from '../services/ProductServices';


function AddProductCategory() {
  const [formValues, setFormValues] = useState({
    name: '',
    image: null,
  });
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', formValues.name);
      if (formValues.image) {
        formData.append('image', formValues.image);
      }

      const res = await ProductServices.AddCategory(formData);
      console.log(res);
      if (res.status === true) {
        alert('Category added successfully');
        setFormValues({
          name: '',
          image: null,
        });
        setPreviewImage('img/placeholder-img.png');
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add category', error);
      alert('Failed to add category');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Category Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/categoryList">Category List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>Title*</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formValues.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-field">
                <label>Upload<span className="red">*</span></label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={handleFileChange}
                  required
                />
                <p>
                  <span>Drop an image or Paste URL (upto resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp
                </p>
                <div className="file-preview text-center">
                  <img id="uploadFile" src={previewImage} alt="your image" />
                </div>
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductCategory;
