import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Css/sideBar.css';

const SideBar = ({ role }) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const storedPermissions = JSON.parse(localStorage.getItem('userPermissions')) || {};
    setPermissions(storedPermissions);
  }, []);

  const handleToggleSubMenu = (menu) => {
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const renderMenuItem = (name, key, icon, subItems) => {
    if (role === 'Admin' || permissions[key]) {
      return (
        <li key={key}>
          {subItems ? (
            <>
              <a onClick={() => handleToggleSubMenu(key)}>
                <i className={`fa fa-${icon}`} /> {name}
                <i className={`fa ${openSubMenu === key ? 'fa-chevron-down' : 'fa-chevron-right'}`} style={{ float: 'right' }} />
              </a>
              {openSubMenu === key && (
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                  {subItems.map(subItem => (
                    <li key={subItem.key} style={{ marginLeft: '35px', marginTop: '5px' }}>
                      <NavLink to={subItem.path} className="sub-link" activeClassName="active">
                        {subItem.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <NavLink to={`/${key}`} activeClassName="active">
              <i className={`fa fa-${icon}`} /> {name}
            </NavLink>
          )}
        </li>
      );
    }
    return null;
  };

  const menuItems = [
    { name: 'Dashboard', key: 'dashboard', icon: 'home' },
    {
      name: 'User Manager', key: 'userManager', icon: 'user-circle-o', subItems: [
        { name: 'User Farmer', key: 'userFarmer', path: '/usermanager' },
        { name: 'User Doctor', key: 'userDoctor', path: '/userdoctor' }
      ]
    },
    {
      name: 'Setting', key: 'setting', icon: 'gear', subItems: [
        { name: 'Profile', key: 'settings', path: '/settings' },
        { name: 'User Permissions', key: 'user-permission', path: '/user-permission' }
      ]
    },
    { name: 'Contact Us', key: 'contact', icon: 'phone' },
    { name: 'Appointment', key: 'apointment', icon: 'file-text' },
    { name: 'Notification', key: 'notification', icon: 'bell-o' },
    { name: 'Feedback', key: 'feedback', icon: 'comments-o' },
    { name: 'FAQs', key: 'faq', icon: 'question-circle' },
    {
      name: 'Pages', key: 'pages', icon: 'address-book-o', subItems: [
        { name: 'About Us', key: 'about-us', path: '/about-us' },
        { name: 'Privacy Policy', key: 'privacy-policy', path: '/privacy-policy' },
        { name: 'Terms & Conditions', key: 'term-condition', path: '/term-condition' },
        { name: 'How It Works', key: 'howtoimage', path: '/howtoimage' },
        { name: 'Community Category', key: 'community-category', path: '/community-category' },
        { name: 'Animal Category', key: 'animal-category', path: '/animal-category' },
        { name: 'Pet Breed', key: 'breed', path: '/breed' }
      ]
    },
    { name: 'Pet List', key: 'view-all-pet-list', icon: 'plus' },
    { name: 'Sell Animal', key: 'sellAnimalList', icon: 'bullseye' },
    { name: 'Verification Request', key: 'verification', icon: 'check' },
    {
      name: 'Product', key: 'product', icon: 'dribbble', subItems: [
        { name: 'Category', key: 'categoryList', path: '/categoryList' },
        { name: 'Product', key: 'productList', path: '/productList' }
      ]
    },
    {
      name: 'Order', key: 'order', icon: 'cart-plus', subItems: [
        { name: 'Order All', key: 'order', path: '/order' },
        { name: 'Pending', key: 'order-pending', path: '/order-pending' },
        { name: 'Confirmed', key: 'order-confirm', path: '/order-confirm' },
        { name: 'Cancel', key: 'order-cancel', path: '/order-cancel' },
        { name: 'Out For Delivery', key: 'order-out-for-delivery', path: '/order-out-for-delivery' },
        { name: 'Delivered', key: 'order-delivered', path: '/order-delivered' }
      ]
    },
    { name: 'Home Slider', key: 'homeslider', icon: 'file-image-o' },
    { name: 'Banner', key: 'banner', icon: 'file-image-o' },
    { name: 'Benefit', key: 'benefit', icon: 'plus-square' },
    { name: 'Push Notification', key: 'push-notification-list', icon: 'bell-o' },
    { name: 'Educational Resources', key: 'education', icon: 'book' },
    { name: 'Admin', key: 'adminuser', icon: 'address-card' },
    { name: 'Doctor Earning', key: 'earning', icon: 'stethoscope' }
  ];

  return (
    <div className="col-md-3 left_col">
      <div className="left_col scroll-view">
        <div className="navbar nav_title" style={{ border: 0 }}>
          <NavLink to="/dashboard" className="site_title">
            <img src="img/logo.svg" alt="Logo" />
          </NavLink>
        </div>
        <div className="clearfix" />
        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
          <div className="menu_section">
            <ul className="nav side-menu">
              {menuItems.map(item => renderMenuItem(item.name, item.key, item.icon, item.subItems))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
