import React, { useState, useEffect } from 'react';
import ProductServices from '../../services/ProductServices';

function UpdateCategory({ selectedData, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    name: '',
    image: null,
  });
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png');

  useEffect(() => {
    if (selectedData) {
      setFormValues({
        name: selectedData.name || '',
        image: null,
      });
      setPreviewImage(selectedData.image || 'img/placeholder-img.png');
    }
  }, [selectedData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        formData.append(key, formValues[key]);
      }
      const res = await ProductServices.UpdateCategory(selectedData._id, formData);
      if (res.status === true) {
        alert('Category updated successfully');
        onSuccess();
        closeModal();
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to update category', error);
      alert('Failed to update category');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Category</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body px-5 pb-0">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="input-field">
                      <label>Main Category*</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formValues.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="input-field">
                      <label>Upload<span className="red">*</span></label>
                      <input
                        type="file"
                        name="image"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                      <p>
                        <span>Drop an image or Paste URL (upto resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp
                      </p>
                      <div className="file-preview text-center">
                        <img id="uploadFile" src={previewImage} alt="your image" />
                      </div>
                    </div>
                    <button className="submit-green-btn" type="submit">
                      SUBMIT
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateCategory;
