import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AnimalServices from '../../services/AnimalServices.js';

function AddAnimalCategory() {
  const [formValues, setFormValues] = useState({
    name: '',
    image: ''
  });
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('image', formValues.image);

    try {
      const res = await AnimalServices.createAnimalCategory(formData);
      console.log(res);
      if (res.status === true) {
        alert('Category added successfully');
        setFormValues({ name: '', image: '' });
        setPreviewImage('img/placeholder-img.png');
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add category', error);
      alert('Failed to add category');
    }
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Animal Category Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/animal-category">Animal Category List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>Title*</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-field">
                <label>Image*</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={handleFileChange}
                />
                <img src={previewImage} alt="Preview" width="100" height="100" />
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAnimalCategory;
