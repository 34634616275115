import React, { useState } from 'react';
import AppointmentServices from '../../services/AppointmentServices';
const ApointmentStatus = ({ user ,onSuccess}) => {
  // Initialize the state based on the user's status
  const [isChecked, setIsChecked] = useState(user?.completeStatus === true);

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = !isChecked ? true : false;
    setIsChecked(!isChecked);
    
      const res = await AppointmentServices.updateApointment(user._id, {
        completeStatus: newStatus
      });

      onSuccess();
        
  };

  return (
   
      <div className="check-box">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
      </div>
    
  );
};

export default ApointmentStatus;
