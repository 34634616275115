import React, { useState, useEffect } from 'react';
import './Header/Css/sideBar.css';
import PermissionServices from '../services/PermissionServices';

const menuItems = [
  { name: 'Dashboard', key: 'dashboard' },
  { name: 'User Manager', key: 'userManager' },
  { name: 'Settings/Profile', key: 'settings' },
  { name: 'Contact Us', key: 'contact' },
  { name: 'Appointment', key: 'appointment' },
  { name: 'Notification', key: 'notification' },
  { name: 'Feedback', key: 'feedback' },
  { name: 'FAQS', key: 'faqs' },
  { name: 'Pages', key: 'pages' },
  { name: 'Pet List', key: 'petList' },
  { name: 'Sell Animal', key: 'sellAnimal' },
  { name: 'Verification Request', key: 'verificationRequest' },
  { name: 'Product', key: 'product' },
  { name: 'Order', key: 'order' },
  { name: 'Home Slider', key: 'homeSlider' },
  { name: 'Banner', key: 'banner' },
  { name: 'Benefit', key: 'benefit' },
  { name: 'Push Notification', key: 'pushNotification' },
  { name: 'Educational Resources', key: 'education' },
  { name: 'Admin', key: 'admin' },
  { name: 'Doctor Earning', key: 'doctorEarning' }
];

const roles = ['Farmer', 'Doctor', 'Helper'];

const UserPermission = () => {
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await PermissionServices.getPermission();
        const data = response.data;
        const formattedPermissions = data.reduce((acc, item) => {
          acc[item.menuItem] = item.roles;
          return acc;
        }, {});
        setPermissions(formattedPermissions);
      } catch (error) {
        console.error('Error loading permissions:', error);
        setError('Error loading permissions. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const handleCheckboxChange = (menuItem, role) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [menuItem]: {
        ...prevPermissions[menuItem],
        [role]: !prevPermissions[menuItem]?.[role]
      }
    }));
  };

  const handleSavePermissions = async () => {
    const formattedPermissions = Object.entries(permissions).map(([menuItem, roles]) => ({
      menuItem,
      roles
    }));

    try {
      await PermissionServices.savePermission({ permissions: formattedPermissions });
      console.log('Permissions saved successfully');
    } catch (error) {
      console.error('Error saving permissions:', error);
      setError('Error saving permissions. Please try again.');
    }
  };

  return (
    <div className="user-permission-container">
      <h2>User Permissions</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <table className="user-permission-table">
            <thead>
              <tr>
                <th>Menu Item</th>
                {roles.map(role => (
                  <th key={role}>{role}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {menuItems.map(menuItem => (
                <tr key={menuItem.key}>
                  <td>{menuItem.name}</td>
                  {roles.map(role => (
                    <td key={role}>
                      <input
                        type="checkbox"
                        checked={permissions[menuItem.key]?.[role] || false}
                        onChange={() => handleCheckboxChange(menuItem.key, role)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button className="save-button" onClick={handleSavePermissions}>Save Permissions</button>
        </>
      )}
    </div>
  );
};

export default UserPermission;
