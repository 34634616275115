import React, { useEffect, useState } from 'react';
import useAsync from '../hooks/useAsync';
import AdminServices from '../services/AdminServices';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

function Dashboard() {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
    seriesDoctor: [],
    seriesMaufacturer: [],
    seriesService: [],
  });

  const { data, error, isLoading } = useAsync(AdminServices.dashboard);

  useEffect(() => {
    if (data && data.data) {
      const months = Array.from({ length: 12 }, (_, index) => moment().month(index).format('MMM'));
      const userCounts = new Array(12).fill(0);
      const userCounts2 = new Array(12).fill(0);
      const userCounts3 = new Array(12).fill(0);
      const userCounts4 = new Array(12).fill(0);

      data.data.farmer?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts[month]++;
      });

      data.data.doctor?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts2[month]++;
      });

      data.data.serviceCenters?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts3[month]++;
      });

      data.data.manufacturers?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts4[month]++;
      });

      setChartData({
        categories: months,
        series: [{ name: 'Farmer', data: userCounts }],
        seriesDoctor: [{ name: 'Doctor', data: userCounts2 }],
        seriesMaufacturer: [{ name: 'Manufacturer', data: userCounts4 }],
        seriesService: [{ name: 'Service Center', data: userCounts3 }],
      });
    }
  }, [data]);

  const chartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
    },
  };

  return (
    <div className="main_container">
      {/* /top navigation */}
      {/* page content */}
      <div className="right_col " role="main">
        <div className="top-sec-heading">
          <h3>Welcome, {localStorage.getItem('name')}.</h3>
          <p>Hello, here is what happening with your app today.</p>
        </div>
        <div className="container-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart.svg" alt="" /></span>
              <p>Statistics</p>
            </div>
          </div>
          <div className="container-box-inner">
            <div className="row">
              <div className="col-md-3">
                <div className="g-box g-box1">
                  <a href="javascript:void(0)">
                    <p>Total Farmer</p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totleFarmer}</h4>
                      <img src="img/total-customer.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box2">
                  <a href="javascript:void(0)">
                    <p>Total Doctor</p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totleDoctor}</h4>
                      <img src="img/dealer.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box3">
                  <a href="javascript:void(0)">
                    <p>Total </p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalServiceCenters}</h4>
                      <img src="img/total-service-center.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box4">
                  <a href="javascript:void(0)">
                    <p>Total Manufacturer</p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totlaManufacturers}</h4>
                      <img src="img/manufacturer.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box5">
                  <a href="javascript:void(0)">
                    <p>Total Advertisement Query</p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalAdvertise}</h4>
                      <img src="img/advertisement.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box6">
                  <a href="javascript:void(0)">
                    <p>Total Subscription</p>
                    <div className="g-box-inner">
                      <h4>20000</h4>
                      <img src="img/subscription.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box7">
                  <a href="javascript:void(0)">
                    <p>Total Help Query</p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalHelp}</h4>
                      <img src="img/query.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="g-box g-box8">
                  <a href="javascript:void(0)">
                    <p>Total Admin User </p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalAdmin}</h4>
                      <img src="img/admin-user.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Customer</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.series}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Doctor</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesDoctor}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        {/* <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Service Center</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesService}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div> */}
        {/* <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Manufacturer</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesMaufacturer}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div> */}
      </div>
      {/* /page content */}
    </div>
  );
}

export default Dashboard;
