import requests from "./httpService";

const BreedServices = {

    getBreed: async () =>{
        return requests.get(`/breed`);
    },
    updateBreed : async (id,body) =>{
        return requests.put(`/breed/${id}`,body);
    },

    createBreed : async (body) =>{
        console.log(body)
        return requests.post(`/breed`,body);
    },
    deleteBreed : async (id) =>{
        return requests.delete(`/breed/${id}`);
    }
  


}
export default BreedServices;