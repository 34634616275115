import React, {useState} from 'react'
import useAsync from '../hooks/useAsync'
import EducationServices from '../services/EducationServices';
import HelpTogal from './TogelButton/TogalButtonForStatus'
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import EducationUpdate from './Update Model/UpdateEducation'
import { Link } from 'react-router-dom'

Modal.setAppElement('#root');

function Education() {
  const {data , error, isLoading, run} = useAsync(EducationServices.getEducation);
  const count = data?.data?.length
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  const handleEditDetails = (help) => {
    setSelectedEdit(help);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (help) => {
    setSelectedEdit(help);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };
  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };
  function truncateText(text, limit) {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  }

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
console.log(data)
  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Education List <span className="badge bg-orange">{count}</span></h2>
          <div className="container-box-top-header-right">
            <Link className="round-add-btn" to="/addEducation"><img src="img/plus.svg" alt="" /></Link>
          </div>
        </div>
        <div className="container-box px-0">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input type="search" name="search" placeholder="Search" />
              <button className="search-btn">Search</button>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{width: '100%'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th className=" w-50">Description</th>
                  <th>Date</th>
                  <th>status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {data?.data?.map((education, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{truncateText(education?.title, 10)}</td>
                  <td>
                    <div className="product-img">
                      <img src={process.env.REACT_APP_URL + education?.image} alt="" style={{ height: '70px', width: '70px', objectFit: 'contain' }} />
                    </div>
                  </td>
                  <td className="address">{truncateText(education?.content, 20)}</td>
                  <td className="payment">{formatDateTime(education?.date)}</td>
                  <td className="status-toggle">
                    <HelpTogal data={education} page="education" onSuccess={run}/>
                  </td>
                  <td>
                  <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(education)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(education)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <EducationUpdate education={selectedEdit} closeModal={closeEditModal} onSuccess={run}/>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="education" closeModal={closeDeleteModal}  onSuccess={run}/>
        </Modal>
      </div>
      
      </>
  )
}

export default Education