// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  body * {
    visibility: hidden;
  }
  .printable-area, .printable-area * {
    visibility: visible;
  }
  .printable-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/Button/Invoice.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;EACb;AACF","sourcesContent":["@media print {\n  body * {\n    visibility: hidden;\n  }\n  .printable-area, .printable-area * {\n    visibility: visible;\n  }\n  .printable-area {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
