import React, { useState, useEffect } from 'react';
import ContectServices from '../services/ContactServices';

function HowToImage() {
  const [formValues, setFormValues] = useState({
    howToImageForUser: null,
    howToImageForDoctor: null,
  });
  const [previewUrls, setPreviewUrls] = useState({
    howToImageForUser: '',
    howToImageForDoctor: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await ContectServices.howToImage();
        // console.log(">>>>>>",res);
        if (res.status === true) {
          const userImageUrl = `${process.env.REACT_APP_URL}${res.data?.howToImageForUser}`;
          const doctorImageUrl = `${process.env.REACT_APP_URL}${res.data?.howToImageForDoctor}`;

          setFormValues({
            howToImageForUser: res.data?.howToImageForUser,
            howToImageForDoctor: res.data?.howToImageForDoctor,
          });
          setPreviewUrls({
            howToImageForUser: userImageUrl,
            howToImageForDoctor: doctorImageUrl,
          });
        } else {
          setError('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: file,
    }));
    setPreviewUrls((prevUrls) => ({
      ...prevUrls,
      [name]: URL.createObjectURL(file),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (formValues.howToImageForUser instanceof File) {
      formData.append('howToImageForUser', formValues.howToImageForUser);
    }
    if (formValues.howToImageForDoctor instanceof File) {
      formData.append('howToImageForDoctor', formValues.howToImageForDoctor);
    }

    // Log formData keys and values for debugging
    for (let key of formData.keys()) {
      console.log(key, formData.get(key));
    }

    try {
      const res = await ContectServices.updateHowToImage(formData);
      if (res.status === true) {
        alert('Data updated successfully');
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to update data', error);
      alert('Failed to update data');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>How To Image</h2>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>How To Image For User*</label>
                <input
                  type="file"
                  name="howToImageForUser"
                  className="form-control"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                {previewUrls.howToImageForUser && (
                  <img
                    src={previewUrls.howToImageForUser}
                    alt="Preview"
                    style={{ width: '100px', height: '100px' }}
                  />
                )}
              </div>
              <div className="input-field">
                <label>How To Image For Doctor*</label>
                <input
                  type="file"
                  name="howToImageForDoctor"
                  className="form-control"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                {previewUrls.howToImageForDoctor && (
                  <img
                    src={previewUrls.howToImageForDoctor}
                    alt="Preview"
                    style={{ width: '100px', height: '100px' }}
                  />
                )}
              </div>
              <button className="submit-green-btn" type="submit">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToImage;
